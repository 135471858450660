import { BehaviorSubject } from "rxjs";

import i18n from "i18n";

import Environment from "../environment";

import User from "../models/User";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
); // receive initial value, only when do next()
const currentNodeSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("businessNode"))
);
const currentBusinessSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("business"))
);

export const AuthService = {
  expirationTime: 60 * 5, // Seconds
  idleInterval: null,
  idleTime: 0,
  currentUser: currentUserSubject.asObservable(),
  currentUserValue: () => {
    return currentUserSubject.value;
  },
  currentNode: currentNodeSubject.asObservable(),
  currentNodeValue: () => {
    return currentNodeSubject.value;
  },
  currentBusiness: currentBusinessSubject.asObservable(),
  currentBusinessValue: () => {
    return currentBusinessSubject.value;
  },

  login: (username, password) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
      body: JSON.stringify({ username, password }),
    };

    return fetch(`${Environment.api}api/token/`, requestOptions).then(
      (response) => {
        if (response.ok) {
          return response.json().then((data) => {
            localStorage.setItem("access", data.access);
            localStorage.setItem("refresh", data.refresh);
            return data;
          });
        }
        throw response;
      }
    );
  },

  confirm: (userId, key) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: userId, key }),
    };

    return fetch(`${Environment.api}api/confirm_email/`, requestOptions).then(
      (response) => {
        if (response.ok) {
          return response.json().then((data) => {
            localStorage.setItem("access", data.access);
            localStorage.setItem("refresh", data.refresh);
            return data;
          });
        }
        throw response;
      }
    );
  },

  refresh: async () => {
    const refresh = localStorage.getItem("refresh");
    if (refresh) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh: refresh }),
      };
      let response = await fetch(
        `${Environment.api}api/token/refresh/`,
        requestOptions
      );
      if (response.status === 200) {
        let data = await response.json();
        localStorage.setItem("access", data.access);
        localStorage.setItem("refresh", data.refresh);
      }
    }
  },

  logout: () => {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    // Remember businessNode
    // localStorage.removeItem('businessNode');
    currentUserSubject.next(null);
    AuthService.removeListeners();
  },

  loadUser: async (refresh = false) => {
    if (refresh) {
      await AuthService.refresh();
    }

    return new Promise((resolve, reject) => {
      const access = localStorage.getItem("access");
      if (!access) {
        reject("No Access");
        return;
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access,
        },
      };
      return fetch(`${Environment.api}api/me/`, requestOptions)
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              if (!data.is_active) {
                AuthService.logout();
                reject(response);
              } else {
                let user = new User(data);
                let language = user.account
                  ? user.account.language
                  : user.language;
                localStorage.setItem("currentUser", JSON.stringify(user));
                localStorage.setItem("i18nextLng", language);

                i18n.changeLanguage(language);
                currentUserSubject.next(user);
                AuthService.setInitialBusiness(user);
                AuthService.setInitialBusinessNode(user);
                AuthService.startListeners();
                resolve(user);
              }
            });
          } else {
            AuthService.logout();
            reject(response);
          }
        })
        .catch((error) => {
          AuthService.logout();
          reject(error);
        });
    });
  },

  getCleanToken() {
    return localStorage.getItem("access");
  },

  getToken() {
    return `Bearer ${localStorage.getItem("access")}`;
  },

  resetIdle: function() {
    const pendingDelta = AuthService.expirationTime - AuthService.idleTime;

    // If detects user activity and token is going to be expired refresh it
    if (/*pendingDelta > 0 &&*/ pendingDelta < 60) {
      AuthService.idleTime = 0;
      AuthService.refresh();
    }
  },

  timerIncrement: function() {
    AuthService.idleTime = AuthService.idleTime + 1;

    // Autologout when idleTime passes the expiration delta
    // if (AuthService.idleTime > AuthService.expirationTime) {
    //   AuthService.removeListeners();
    //   AuthService.logout();
    // }
  },

  removeListeners: () => {
    document.removeEventListener("keypress", AuthService.resetIdle, false);

    if (AuthService.idleInterval) clearInterval(AuthService.idleInterval);
  },

  startListeners: () => {
    AuthService.idleInterval = setInterval(AuthService.timerIncrement, 1000);
    document.addEventListener("mousemove", AuthService.resetIdle, false);
    document.addEventListener("keypress", AuthService.resetIdle, false);
  },

  getStoragedAccounts: () => {
    const storaged = JSON.parse(localStorage.getItem("rememberedAccounts"));
    if (!storaged) {
      return [];
    }

    return storaged;
  },

  setStoragedAccounts: (accounts) => {
    localStorage.setItem("rememberedAccounts", JSON.stringify(accounts));
  },

  getCurrentNodeCode() {
    let node = AuthService.currentNodeValue();
    if (node) {
      return node.code;
    }
    return 0;
  },

  getBusinessNodeStore() {
    return JSON.parse(localStorage.getItem("businessNode"));
  },

  setInitialBusinessNode(user) {
    let business = AuthService.getBusinessStore();
    if (!business) {
      AuthService.setBusinessNode(null);
    }

    let node = AuthService.getBusinessNodeStore();
    const nodes = user.account.nodes.filter(
      (n) => n.business_id == business.id
    );
    if (user.account && nodes.length > 0) {
      if (node) {
        if (!nodes.map((n) => n.code).includes(node.code)) {
          node = nodes[0];
        }
      } else {
        node = null;
      }
    } else node = null;
    AuthService.setBusinessNode(node);
  },

  setBusinessNode(businessNode) {
    localStorage.setItem("businessNode", JSON.stringify(businessNode));
    currentNodeSubject.next(businessNode);
  },

  getCurrentBusinessId() {
    let business = AuthService.currentBusinessValue();
    if (business) {
      return business.id;
    }
    return null;
  },

  getBusinessStore() {
    return JSON.parse(localStorage.getItem("business"));
  },

  setInitialBusiness(user) {
    let business = AuthService.getBusinessStore();
    if (user.account && user.account.business.length > 0) {
      if (business) {
        if (!user.account.business.map((n) => n.id).includes(business.id)) {
          business = user.account.business[0];
        }
      } else {
        business = user.account.business[0];
      }
    } else {
      business = null;
    }

    AuthService.setBusiness(business);
  },

  setBusiness(business) {
    localStorage.setItem("business", JSON.stringify(business));
    currentBusinessSubject.next(business);
  },

  getPermissions() {
    let business = AuthService.currentBusinessValue();
    if (business) {
      let user = AuthService.currentUserValue();
      const roles = user.roles.filter(r => r.business_id === business.id);
      let permissions = [];
      for (let r of roles) {
        permissions = permissions.concat(r.permissions.map(p => p.name));
      }
      permissions = new Set(permissions);
      return Array.from(permissions);
    }
    return [];
  }
};
