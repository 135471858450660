import React from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";

import "./styles.scss";
import SelectAutocomplete from "shared/components/autocomplete";

export class DescriptionForm extends React.Component {
  render() {
    const {
      client,
      handleChange,
      handleSelect,
      countries,
      regions,
      provinces,
      cities,
      error
    } = this.props;

    return (
      <>
        <div
          className="client-description-field"
          data-automation-id="input-name"
        >
          <MainInput
            label={"Nombre"}
            required={true}
            id={"client-name"}
            name="name"
            value={client ? client.name : ""}
            handleChange={handleChange}
            maxLength={30}
            error={error.name && error.name}
          />
        </div>
        <div
          className="client-description-field"
          data-automation-id="input-business-name"
        >
          <MainInput
            label={"Razon Social"}
            required={true}
            id={"client-business-name"}
            name="business_name"
            value={client ? client.business_name : ""}
            handleChange={handleChange}
            maxLength={30}
            error={error.business_name && error.business_name}
          />
        </div>
        <div
          className="client-description-field"
          data-automation-id="input-document"
        >
          <MainInput
            label={"Documento"}
            required={true}
            id={"client-document"}
            name="document"
            value={client ? client.document : ""}
            handleChange={handleChange}
            maxLength={100}
            error={error.document && error.document}
          />
        </div>
        <div
          className="client-description-field"
          data-automation-id="input-country"
        >
          <SelectAutocomplete
            id="client-country"
            name="country"
            label={"País"}
            options={countries}
            placeholder={"País"}
            selected={client ? client.country : {}}
            required={true}
            error={error.country && error.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "country")
            }
          />
        </div>
        <div
          className="client-description-field"
          data-automation-id="input-region"
        >
          <SelectAutocomplete
            id="client-region"
            name="region"
            label={"Comunidad"}
            options={regions}
            placeholder={"Comunidad"}
            selected={client ? client.region : {}}
            required={true}
            disabled={!client || !client.country || client.country === ""}
            error={error.region && error.region}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "region")
            }
          />
        </div>
        <div
          className="client-description-field"
          data-automation-id="input-state"
        >
          <SelectAutocomplete
            id="client-state"
            name="state"
            label={"Provincia"}
            options={provinces}
            placeholder={"Provincia"}
            selected={client ? client.state : {}}
            disabled={!client || !client.region || client.region === ""}
            required={true}
            error={error.country && error.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "state")
            }
          />
        </div>
        <div
          className="client-description-field"
          data-automation-id="input-locality"
        >
          <SelectAutocomplete
            id="client-locality"
            name="locality"
            label={"Municipio"}
            options={cities}
            placeholder={"Municipio"}
            selected={client ? client.locality : {}}
            disabled={!client || !client.state || client.state === ""}
            required={true}
            error={error.country && error.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "city")
            }
          />
        </div>
        <div
          className="client-description-field"
          data-automation-id="input-date"
        >
          <MainInput
            label={"Fecha de ingreso"}
            required={false}
            id={"client-date"}
            name="entry_date"
            type="date"
            disabled={true}
            value={client ? client.entry_date : ""}
            handleChange={handleChange}
            maxLength={100}
            error={error.entry_date && error.entry_date}
          />
        </div>
      </>
    );
  }
}

export default DescriptionForm;
