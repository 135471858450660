import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useRequest from "hooks/useRequest";

import { EventRepository } from "helpers/EventRepository";

import i18n from "i18n";
import { PublicService } from "services/PublicService.jsx";

import { steps } from "./data/steps";
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import StepProgress from "components/MainApp/atoms/Wizard/StepProgress";
import StepOne from "./Wizard/Step1";
import StepTwo from "./Wizard/Step2";
import StepThree from "./Wizard/Step3";

import "./styles.scss";
import { ClientService } from "../../../../services/ClientService";
import { Client } from "models/Persons";
import { formatDateToYyyyMmDd } from "helpers/Parsers";

const today = new Date();

const initialClient = {
  type: "CLIENT",
  name: "",
  business_name: "",
  nif: "",
  country: "",
  region: "",
  state: "",
  locality: "",
  entry_date: formatDateToYyyyMmDd( new Date()),
  address: "",
  phone: "",
  email: "",
};

const AddClient = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();
  const [activeStep, setActiveStep] = useState("0");
  const [client, setClient] = useState(new Client(initialClient));
  const [countries, setcountries] = useState([]);
  const [regions, setregions] = useState([]);
  const [provinces, setprovinces] = useState([]);
  const [cities, setcities] = useState([]);
  const headerOptions = [];

  useEffect(() => {
    let countriesAux = [];
    PublicService.countries().then((response) => {
      countriesAux = response;
      setcountries(response);
    });

    if (props.match.params.key) {
      const id = props.match.params.key;

      beforeSubmit();
      ClientService.client(id).then((client) => {
        let regions;
        let states;
        let localities;

        let regionsPromises = PublicService.regions(client.country);
        let statesPromises = PublicService.states(
          client.country,
          client.region
        );
        let citiesPromises = PublicService.cities(
          client.country,
          client.region,
          client.state
        );

        Promise.all([regionsPromises, statesPromises, citiesPromises]).then(
          (responses) => {
            regions = responses[0];
            setregions(regions);

            states = responses[1];
            setprovinces(states);

            localities = responses[2];
            setcities(localities);

            client.country = countriesAux.find(
              (c) => c.value === client.country
            );
            client.locality = localities.find(
              (c) => c.value === client.locality
            );
            client.region = regions.find((c) => c.value === client.region);
            client.state = states.find((c) => c.value === client.state);

            setClient(client);
            afterSubmit();
          }
        );
      });
    }
  }, []);

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }

    if (
      [
        "name",
        "business_name",
        "document",
        "state",
        "locality",
        "region",
        "entry_date",
      ].includes(Object.keys(errors)[0])
    ) {
      setActiveStep("0");
    }

    if (["email", "phone", "address"].includes(Object.keys(errors)[0])) {
      setActiveStep("1");
    }
  };

  const handleFinish = (e) => {
    e.preventDefault();
    let clientAux = { ...client };
    clientAux.country = clientAux.country.value;
    clientAux.region = clientAux.region.value;
    clientAux.state = clientAux.state.value;
    clientAux.locality = clientAux.locality.value;
    clientAux.entry_date += " 12:00:00";

    beforeSubmit();
    if (clientAux.code) {
      ClientService.updateClient(clientAux.code, clientAux)
        .then((client) => {
          afterSubmit();
          EventRepository.notificationSend({
            label: i18n.t("Se modifico cliente de manera exitosa."),
            type: "success",
          });
          history.push(`/admin/clients`);
        })
        .catch((error) => {
          console.log(error);
          dealWithError(error, "generic.error", (message, errors) => {
            analizeErrors(message, errors);
          });
          afterSubmit();
        });
    } else {
      ClientService.createClient(clientAux)
        .then((client) => {
          setClient(client);
          afterSubmit();
          EventRepository.notificationSend({
            label: i18n.t("Se añadio cliente de manera exitosa."),
            type: "success",
          });
          history.push(`/admin/clients`);
        })
        .catch((error) => {
          console.log(error);
          dealWithError(error, "generic.error", (message, errors) => {
            analizeErrors(message, errors);
          });
          afterSubmit();
        });
    }
  };

  const handleGoBack = () => {
    history.push(`/admin/clients`);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let clientAux = { ...client };
    clientAux[name] = value;

    setClient(new Client(clientAux));
  };

  const handleSelect = (selectedOption, type) => {
    let clientAux = { ...client };
    switch (type) {
      case "country":
        clientAux.country = selectedOption;
        clientAux.region = "";
        clientAux.state = "";
        clientAux.locality = "";
        PublicService.regions(clientAux.country.value).then((response) => {
          setregions(response);
        });
        setClient(new Client(clientAux));

        break;
      case "region":
        clientAux.region = selectedOption;
        clientAux.state = "";
        clientAux.locality = "";
        PublicService.states(
          clientAux.country.value,
          clientAux.region.value
        ).then((response) => {
          setprovinces(response);
        });
        setClient(new Client(clientAux));

        break;
      case "state":
        clientAux.state = selectedOption;
        clientAux.locality = "";
        PublicService.cities(
          clientAux.country.value,
          clientAux.region.value,
          clientAux.state.value
        ).then((response) => {
          setcities(response);
        });
        setClient(new Client(clientAux));

        break;
      case "city":
        clientAux.locality = selectedOption;
        setClient(new Client(clientAux));

        break;

      default:
        break;
    }
  };

  const validateStep0 = () => {
    if (client) {
      return (
        client.name !== "" &&
        client.country !== "" &&
        client.region !== "" &&
        client.state !== "" &&
        client.locality !== "" /*&&
        client.entry_date !== ""*/
      );
    } else {
      return false;
    }
  };

  const validateStep1 = () => {
    if (client) {
      return (
         client.phone !== "" && client.phone !== null
      );
    } else {
      return false;
    }
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const step0 = (
    <StepOne
      handleContinue={() => setActiveStep("1")}
      client={client}
      handleSelect={handleSelect}
      handleChange={handleChange}
      countries={countries}
      regions={regions}
      provinces={provinces}
      cities={cities}
      enableContinue={validateStep0()}
      error={errors}
    ></StepOne>
  );

  const step1 = (
    <StepTwo
      handleContinue={() => setActiveStep("2")}
      goBack={() => goToStep("0")}
      handleChange={handleChange}
      enableContinue={validateStep1()}
      client={client}
      error={errors}
    ></StepTwo>
  );

  const step2 = (
    <StepThree
      handleFinish={handleFinish}
      goBack={() => goToStep("1")}
      description={client && client.getDescriptionPanelView()}
      contact={client && client.getContactPanelView()}
    ></StepThree>
  );

  // const stepList = [step0, step1, step2];
  const stepList = {
    "0": step0,
    "1": step1,
    "2": step2,
  };

  const content = (
    <div className={`add-client-content`}>
      <div className={`add-client-wrapper`}>
        <StepProgress steps={steps} activeStep={activeStep} />
        <div className={`add-client-step-wrapper`}>{stepList[activeStep]}</div>
      </div>
    </div>
  );

  return (
    <div className="add-client-container">
      <AdminLayout
        headerTitle={i18n.t("Nuevo Cliente")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default AddClient;
