import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { AuthService } from "../../../../services/AuthService";

import Icon from "../../atoms/Icon/icon";
import imageFileWhite from "../../../../assets/images/logo blanco.png";
import logoCompleto from "assets/images/Logo.svg";
import { isMobile } from "helpers/Mobile";
import data from "./data";

import "./styles.scss";

export const Sidebar = () => {
  const [permissions, setPermissions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const homeLogo = expanded ? logoCompleto : imageFileWhite;

  useEffect(() => {
    setPermissions(AuthService.getPermissions());
  }, []);

  const renderItem = item => {
    if (isMobile && !item.mobile) return;
    return (
      <NavLink
        className={`item${expanded ? " expanded" : ""}`}
        activeClassName="active-item"
        to={item.to}
        key={item.to}
      >
        <Icon
          name={item.icon}
          fill={isMobile ? "#0000003d" : "#FFFFFF"}
          width={isMobile ? 26 : 40}
          height={28}
        ></Icon>
        <div className={`item-title${expanded ? " expanded" : ""}`}>
          {item.title}
        </div>
        <div className="inner-sep"></div>
      </NavLink>
    );
  };

  const mobileHome = (
    <NavLink
      className={`item${expanded ? " expanded" : ""}`}
      activeClassName="active-item"
      to="/admin/dashboard"
    >
      <Icon name={"yax"} width={28} height={28}></Icon>
      <div className="inner-sep"></div>
    </NavLink>
  );

  return (
    <div className={`sidebar-container${expanded ? " expanded" : ""}`}>
      {!isMobile &&
      <NavLink
        className={`item home${expanded ? " expanded" : ""}`}
        to="/admin/dashboard"
        activeClassName="active-home"
      >
        <div className={`nav-icon${expanded ? " expanded" : ""}`}>
          <img src={homeLogo} alt="logo" />
        </div>
      </NavLink>
    }
      {isMobile && mobileHome}
      {data
        .filter(i => {
          return permissions.includes(i.permission) || i.permission === "all";
        })
        .map(item => renderItem(item))}
      {!isMobile && (
        <div
          className={`item${expanded ? " expanded" : ""}`}
          activeclassnsame="active-item"
          to="/admin/account"
          onClick={() => AuthService.logout()}
        >
          <Icon
            name="cerrar-sesion"
            fill={"#FFFFFF"}
            width={isMobile ? 26 : 28}
            height={28}
          ></Icon>
          <div className={`item-title${expanded ? " expanded" : ""}`}>
            Cerrar Sesion
          </div>
          <div className="inner-sep"></div>
        </div>
      )}

      <div className={"sidebar-toggle"} onClick={() => setExpanded(!expanded)}>
        <i
          className={`icon ${
            expanded ? "icon-chevron-izquierda" : "icon-chevron-derecha"
          } `}
        ></i>
      </div>
    </div>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
