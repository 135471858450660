import React, { useState, useEffect, useRef } from "react";
import useFilters from "../../../../hooks/useFilters";
import FileSaver from "file-saver";

import { useHistory } from "react-router-dom";
import { useStore } from "../../../../store/context";
import i18n from "i18n";

import { OrderService } from "../../../../services/OrderService";
import { PublicService } from "../../../../services/PublicService";
import { formatDateToYyyyMmDd } from "helpers/Parsers";

import AdminLayout from "../../../../components/MainApp/layouts/DesktopLayout";
import MainNotification from "../../../../components/MainApp/atoms/Notification";
import FilterPanel from "../../../../components/MainApp/organisms/Filters/FilterPanel";
import OrdersTable from "../../../../components/MainApp/organisms/Orders/PrepOrders/Table";
import Pagination from "../../../../components/MainApp/atoms/Pagination";
import Loader from "../../../../components/MainApp/atoms/Loader";

import tableHeaders from "./data/tableHeaders";
import {
  filterData as FilterDataSource,
  filterTypeMap
} from "./data/filterData";
import prepTypeMapping from "./data/PrepTypeMapping";
import { isMobile } from "../../../../helpers/Mobile";
import "./styles.scss";
import excel from "assets/images/excel.svg";
import excelwhite from "assets/images/excelwhite.svg";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";
import { NotFoundItems } from "views/Admin/components/NotFoundItems";

const initialFilters = {
  page_size: 10,
  search: "",
  ordering: "-created_at",
  status: undefined,
  node: undefined,
  destination: undefined
};

let scrolling = false;

export const PrepOrdersView = () => {
  const [prepOrderFilters, setPrepOrderFilters] = useState();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [noItems, setNoItems] = useState(false);
  const [filterData, setFilterData] = useState(FilterDataSource);

  const [orders_name, setOrders_name] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const myRef = useRef(0);

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    page,
    setPage,
    items,
    setItems,
    pager,
    setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen
  ] = useFilters(
    initialFilters,
    prepOrderFilters,
    filterTypeMap,
    isMobile,
    tableHeaders
  );

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: () => {
        history.push("/admin/orders/preparation/load");
      },
      tooltip: "Agregar orden"
    }
  ];

  useEffect(() => {
    fillFilterData();
    OrderService.ordersCode().then(response => {
      setOrders_name(response);
    });
  }, []);

  useEffect(() => {
    setMobilePanelOpen(false);
    const first_load =
      JSON.stringify(initialFilters) === JSON.stringify(filters);
    filter(first_load);
    // Acomodar orden
    const keyOrdering =
      filters.ordering.charAt(0) === "-"
        ? filters.ordering.substr(1)
        : filters.ordering;
    if (keyOrdering in tableHeaders) {
      const newHeaders = { ...header };
      newHeaders[keyOrdering]["active"] = true;
      newHeaders[keyOrdering]["direction"] =
        filters.ordering === keyOrdering ? true : false;
      setHeader(newHeaders);
    }
  }, []);

  useEffect(() => {
    if(isFirstLoad){
      setIsFirstLoad(false);
      return;
    }
    filter(false);
    setPrepOrderFilters({
      filters: filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType
    });
  }, [filters, page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  const handleScroll = event => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || submitted || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function(page) {
    setPage(page);
  };

  const filter = function(firstLoad = false) {
    let append = isMobile;
    if (!append) {
      setItems([]);
    }

    //BEFORE SUBMIT
    document.body.style.cursor = "wait";
    setSubmitted(true);
    // setErrors({})
    // setMessage({})

    const data = { ...filters, page: page };
    data["status"] = data["status"] ? data["status"].join(",") : undefined;
    data["node"] = data["node"] ? data["node"].join(",") : undefined;
    data["destination"] = data["destination"]
      ? data["destination"].join(",")
      : undefined;
    data["from_date"] = data["date"]
      ? formatDateToYyyyMmDd(data["date"][0])
      : undefined;
    data["from_date_to"] = data["date"]
      ? formatDateToYyyyMmDd(data["date"][1])
      : undefined;
    delete data["date"];

    setloading(true);
    OrderService.prepOrders(data)
      .then(response => {
        //this.afterSubmit();
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;

        // if (firstLoad && response.count === 0) {
        //   history.push("/admin/products/load");
        //   setNoProducts(true)
        //   return;
        // }

        const newOrders = append
          ? [...items, ...response.results]
          : response.results;

        setloading(false);
        setItems(newOrders);
        setPager({
          next: response.next,
          previous: response.previous,
          count: response.count,
          countPages: Math.ceil(response.count / filters.page_size)
        });
      })
      .catch(e => {
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;
      });
  };

  const fillFilterData = function() {
    let filterDataAux = [...filterData];

    PublicService.nodes().then(rta => {
      let options = rta.map(n => {
        n.label = n.name;
        n.selected = false;
        n.type = "node";
        n.id = n.code;
        return n;
      });
      filterDataAux[1].options = options;
    });

    if (isMobile) {
      filterDataAux[4] = {
        type: "twoOption",
        options: [
          {
            selected: false,
            label: "Menos reciente",
            value: "created_at"
          }
        ],
        label: "Ordenar por",
        firstOptionLabel: "Más reciente",
        value: "-created_at"
      };
    }
    setFilterData(filterDataAux);
  };

  const downloadReport = () => {
    const data = { ...filters };
    data["status"] = data["status"] ? data["status"].join(",") : undefined;
    data["node"] = data["node"] ? data["node"].join(",") : undefined;
    data["destination"] = data["destination"]
      ? data["destination"].join(",")
      : undefined;

    setloading(true);
    OrderService.prepDownloadReport(data)
      .then(blob => {
        setloading(false);
        FileSaver.saveAs(blob, "reporte.xls");
      })
      .catch(error => {
        setloading(false);
      });
  };

  const goToDetailPage = event => {
    history.push(
      `/admin/orders/preparation/details/${event.currentTarget.dataset.requestorderId}`
    );
  };

  const goToEditPage = event => {
    history.push(
      `/admin/orders/preparation/edit/${event.currentTarget.dataset.requestorderId}`
    );
  };

  const headerButtons = (
    <div
      className="excel-button"
      onClick={downloadReport}
      style={{
        width: "30px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
      }}
    >
      <img style={{ height: "30px" }} src={isMobile ? excelwhite : excel} />{" "}
    </div>
  );

  const mobileContent = (
    <React.Fragment>
      <div className="orders-request-table-filter-container">
        <SimpleSearchPanel
          placeholder="Busca por nombre o código"
          handleSubmit={handleSearch}
        />
      </div>

      <div
        className={`orders-request-table-mobile-panel ${
          mobilePanelOpen ? "opened" : ""
        } `}
      >
        <FilterPanel
          resultList={orders_name}
          placeholder="Busca por nombre o código"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
          closeMobilePanel={() => {
            setMobilePanelOpen(false);
          }}
        ></FilterPanel>
      </div>
      <div className="orders-request-table-table">
        <div className="orders-request-table-top-table">
          <div className="orders-request-table-top-total">
            Total{" "}
            <span className="orders-request-table-top-total-bold">
              ({pager.count})
            </span>
          </div>
          <div
            className={`orders-request-table-top-sort ${
              applied ? "orders-request-table-active-filter" : ""
            }`}
            onClick={() => {
              setMobilePanelOpen(true);
            }}
          >
            <i className="icon-iconos_filtros orders-request-table-top-icon"></i>
          </div>
        </div>
        <OrdersTable
          requestOrders={items}
          headers={header}
          handleClickRequestOrder={goToDetailPage}
          handleClickDraft={goToEditPage}
          mapTypes={prepTypeMapping}
        />
      </div>

      {items.length === 0 && !submitted && (
        <NotFoundItems />
      )}
    </React.Fragment>
  );

  // DESKTOP START
  const desktopContent = (
    <div className="orders-request-content-wrapper">
      <FilterPanel
        resultList={orders_name}
        placeholder="Busca por nombre o código"
        searchValue={filters.search}
        handleSubmitSearch={handleSearch}
        filters={filterData}
        selectedFiltersAll={selectedFiltersAll}
        selectedFiltersByType={selectedFiltersByType}
        handleApplyFilters={handleApplyFilters}
      ></FilterPanel>

      {items.length > 0 && (
        <div className="orders-request-table-table">
          <div className="orders-request-table-top-table">
            <div className="orders-request-table-top-total">
              TOTAL{" "}
              <span className="orders-request-table-top-total-bold">
                ({pager.count})
              </span>
            </div>
            <div className="orders-request-table-top-sort">
              <div
                data-automation-id="orders-request-table-sort"
                className={
                  filters.ordering.includes("created_at")
                    ? "orders-request-table-top-sort orders-request-table-active-filter"
                    : "orders-request-table-top-sort"
                }
                onClick={() => {
                  handleFiltersChangeOrdering(
                    "ordering",
                    filters.ordering === "-created_at"
                      ? "created_at"
                      : "-created_at"
                  );
                  resetOrdering();
                }}
              >
                {filters.ordering === "created_at" ? (
                  <React.Fragment>
                    Menos recientes{" "}
                    <i className="icon-chevron orders-request-table-top-icon"></i>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    Más recientes{" "}
                    <i className="icon-chevron-down orders-request-table-chevron-down orders-request-table-top-icon"></i>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <OrdersTable
            requestOrders={items}
            headers={tableHeaders}
            headerClickHandler={headerOrderingHandler}
            handleClickRequestOrder={goToDetailPage}
            handleClickDraft={goToEditPage}
            mapTypes={prepTypeMapping}
          />
        </div>
      )}

      {items.length === 0 && !submitted && (
        <NotFoundItems />
      )}
      {items.length > 0 && (
        <div>
          <Pagination
            pageQuantity={pager.countPages}
            currentPage={page}
            pageChangeHandler={handlePagerChange}
          />
        </div>
      )}
    </div>
  );
  // DESKTOP END

  const content = (
    <div className="orders-request-content-container">
      {isMobile ? mobileContent : desktopContent}
    </div>
  );

  return (
    <div className="orders-request-container">
      <AdminLayout
        headerTitle={i18n.t("Salidas")}
        headerOptions={headerOptions}
        content={content}
        navHidden={false}
        customButtons={headerButtons}
        goBackFunc={() => {
          history.push("/admin/orders");
        }}
      ></AdminLayout>
      {loading && <Loader />}
      <MainNotification
        label={"Tu producto se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default PrepOrdersView;
