import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MultiSelectInput from "components/MainApp/atoms/Forms/MultiSelectInputGeneric";
import CostumCheckbox from "components/Auth/atoms/forms/CheckboxCostum";
import ProductAddedList from "components/MainApp/organisms/Orders/RequestOrders/ProductAddedList";
import SearchWithComponentsNoForm from "components/MainApp/atoms/Filters/SearchWithComponentsNoForm";
import { isMobile } from "helpers/Mobile";

import "./styles.scss";

export class AddEmbalajeStep1 extends React.Component {
  render() {
    const { embalaje, handleChange, handleSelect, handleContinue, enableContinue, error, types, paletType, changePaletType, editMode,
            //product selector props
            resultList,
            searchValue,
            products,
            totalProducts,
            handleSelectToAddProduct,
            handleSelectedProductQuantityChange,
            handleAdd,
            handleAddProduct,
            selectedProducts,
            handleDeleteAddedProduct,
            changePage,
            pagination
          } = this.props;

    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-embalaje-wizard-step-one-content">

        <div className="add-embalaje-top">
          <div className="add-embalaje-left">
            <div className="add-embalaje-wizard-step-one-field" data-automation-id="input-type">
              <MultiSelectInput
                items={types}
                required={true}
                label="Tipo de embalaje"
                selected={embalaje.type}
                multiple={false}
                handleChange={(e) => handleSelect(e, 'type')}
                disabled={editMode}
              />
            </div>

            {isMobile &&<div className="add-embalaje-wizard-step-one-field">
            {embalaje?.type?.id === 'PALLET' &&
              <div className="add-embalaje-palet-options">
                  <CostumCheckbox
                    label={"Cajas"}
                    checked={paletType === "BOX" }
                    handleChange={!editMode && changePaletType}
                    disabled={true}
                  ></CostumCheckbox>
                  <CostumCheckbox
                    label={"Unidades"}
                    checked={paletType === "UNITS"}
                    handleChange={!editMode && changePaletType}
                    disabled={true}
                  ></CostumCheckbox>
              </div>
            }
          </div>}

            <div className="add-embalaje-wizard-step-one-field" data-automation-id="input-sku">
              <MainInput
                label={"Código"}
                required={true}
                id={"embalaje-sku"}
                name="sku"
                value={embalaje ? embalaje.sku : ''}
                handleChange={handleChange}
                maxLength={100}
                error={error.sku && error.sku}
              />
            </div>
            <div className="add-embalaje-wizard-step-one-field" data-automation-id="input-name">
              <MainInput
                label={"Nombre"}
                required={true}
                id={"embalaje-name"}
                name="name"
                value={embalaje ? embalaje.name : ''}
                handleChange={handleChange}
                maxLength={100}
                error={error.name && error.name}
              />
            </div>
          </div>
          {!isMobile &&<div className="add-embalaje-right">
            {embalaje?.type?.id === 'PALLET' &&
              <div className="add-embalaje-palet-options">
                  <CostumCheckbox
                    label={"Cajas"}
                    checked={paletType === "BOX" }
                    handleChange={!editMode && changePaletType}
                    disabled={true}
                  ></CostumCheckbox>
                  <CostumCheckbox
                    label={"Unidades"}
                    checked={paletType === "UNITS"}
                    handleChange={!editMode && changePaletType}
                    disabled={true}
                  ></CostumCheckbox>
              </div>
            }
          </div>}
        </div>
        <div className="add-embalaje-bottom">
          <div className="add-embalaje-wizard-step-one-field" data-automation-id="input-search">
            <SearchWithComponentsNoForm
              placeholder = {"Busca por código o nombre"}
              resultList = {resultList}
              value = {searchValue}
              onlyStockHidden={true}
              handleChange={handleChange}
              handleSelectToAddProduct={handleSelectToAddProduct} handleSelectedProductQuantityChange={handleSelectedProductQuantityChange} handleAdd={handleAdd}
              selectedProducts={selectedProducts}
            />

            {products.length > 0 && <ProductAddedList total={totalProducts} products={products} edit={true} handleEdit={handleAddProduct} handleDelete={handleDeleteAddedProduct}
              changePage={changePage}
              pagination={pagination}
            ></ProductAddedList>}
          </div>

        </div>
        <div className={"add-embalaje-wizard-step-one-action-wrapper"}>
        <button type="button" className={`add-embalaje-wizard-step-one-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-embalaje-wizard-step-one-container">
        <Step title="Ingresa información del embalaje" content={stepContent}></Step>
      </div>
    );
  }
}

export default AddEmbalajeStep1;
