import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemButton, Grid, ListItemText, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import i18n from "i18n";
import style from "./DropDownTags.module.scss";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';


const DropDownTags = ({ listTags }) => {
    const [open, setOpen] = React.useState(false);
    const [tagsQuantity, setTagsQuantity] = useState(0);
    const [path, setPath] = useState("");
    const [sucursal, setSucursal] = useState("");

    useEffect(() => {
        setTagsQuantity(listTags.length);
        setSucursal(listTags[0].stock.location.node.name);
        setPath(listTags[0].stock.location.path_name);
    }, []);

    const handleClick = () => {
        setOpen(!open);
    };

    const theme = createTheme({
        typography: {
            fontFamily: [
                'Ubuntu-Medium',
            ].join(','),
        },
    });

    return (
        <div className={style['content']}>
            <ThemeProvider theme={theme}>
                <ListItemButton onClick={handleClick} className={style['head']}>
                    <Grid container >
                        <Grid item md={6} sm={11} xs={11} >
                            <ListItemText className={style['tag']}>
                                {tagsQuantity} {tagsQuantity > 1 ? i18n.t("Tags") : i18n.t("Tag")}
                            </ListItemText>
                        </Grid>
                        <Grid item md={5} sm={11} xs={11} >
                            <ListItemText className={style['path']}>
                                Sucursal {sucursal} | {path}
                            </ListItemText>
                        </Grid>
                        <Grid item xs={1} className={style['row-icon']}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </Grid>

                    </Grid>
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit >
                    <Divider />
                    <Grid container spacing={1} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }} >
                        <Grid item md={6}   >
                            <ListItemText primary="RFID" sx={{ marginLeft: '15px' }} />
                        </Grid>
                        <Grid item md={6}  >
                            <ListItemText primary="EPC" sx={{ marginLeft: '7.5px' }} />
                        </Grid>
                    </Grid>
                    {listTags.map((tag) => {
                        return (
                            <>
                                <Divider />
                                <List sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: '15px',
                                }}
                                    className={style['list-content']}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item md={6} sm={12} xs={12}  >
                                            <div style={{ display: "flex" }}>
                                                <ListItemText sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                                                    RFID
                                                </ListItemText>
                                                <ListItemText>
                                                    {tag.stock.tag.rfid}
                                                </ListItemText>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12} >
                                            <div style={{ display: "flex" }}>
                                                <ListItemText sm={6} xs={6} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                                                    EPC
                                                </ListItemText>
                                                <ListItemText sm={6} xs={6} sx={{ fontSize: "10px" }}>
                                                    {parseInt(tag.stock.tag.rfid).toString(16)}
                                                </ListItemText>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </List>
                            </>
                        );
                    })}
                </Collapse>
            </ThemeProvider>
        </div>
    );
};

export default DropDownTags;