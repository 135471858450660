import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import { TagService } from "services/TagService";

import useRequest from "hooks/useRequest";
import { isMobile } from "helpers/Mobile";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";

import MainNotification from "components/MainApp/atoms/Notification";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";
import GenericTable from "components/MainApp/organisms/GenericTable";
import Pagination from "components/MainApp/atoms/Pagination";

import NodeImg from "./storefront-24px.svg";
import columns from "./columns";

import "./styles.scss";
import { NotFoundItems } from "views/Admin/components/NotFoundItems";

let scrolling = false;

export const ConfigTagListView = () => {
  const history = useHistory();
  const [noItems, setNoItems] = useState(false);
  const [configTags, setconfigTags] = useState([]);
  const [configTagsTable, setconfigTagsTable] = useState([]);

  const isFirstRun = useRef(true);
  const [pager, setPager] = useState({
    next: null,
    previous: null,
    count: 0,
    countPages: 0,
  });
  const [page, setPage] = useState(1);
  let pageSize = 10;
  const [isSearched, setSearched] = useState(false);


  const { loading, beforeSubmit, afterSubmit } = useRequest();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  useEffect(() => {
    let firstRun = false;
    if (isFirstRun.current) {
      firstRun = true;
      isFirstRun.current = false;
    }

    filter({ page: page, page_size: pageSize }, firstRun);
  }, [page]);

  const filter = (params, first = false) => {
    beforeSubmit();

    TagService.configTagsPaginated(params)
      .then((response) => {
        afterSubmit();

        const newConfigTags =
          isMobile && params.page !== 1
            ? [...configTags, ...response.results]
            : response.results;

        if (first && newConfigTags.length < 1) setNoItems(true);

        setconfigTags(newConfigTags);
        const table = newConfigTags.map((configTag) =>
          configTag.getGenericTableView()
        );

        setconfigTagsTable(table);
        setPager({
          next: response.next,
          previous: response.previous,
          count: response.count,
          countPages: Math.ceil(response.count / pageSize),
        });

        scrolling = false;
      })
      .catch((_) => {
        afterSubmit();
        scrolling = false;
      });
  };

  const handleScroll = (event) => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || loading || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function(page) {
    setPage(page);
  };

  const handleSearch = (value) => {
    setSearched(false);
    filter({ page: 1, page_size: pageSize, search: value });
    setSearched(true);
  };

  const createConfigTag = () => {
    history.push(`/admin/config_tags/add`);
  };

  const associateTag = () => {
    history.push(`/admin/tags/associate`);
  };

  const goToDetail = (id) => {
    history.push(`/admin/config_tags/detail/${id}`);
  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: createConfigTag,
      tooltip: i18n.t("Agregar Plantilla"),
    },

    {
      icon: "icon-vinculartag-2",
      name: "associate",
      handler: associateTag,
      tooltip: i18n.t("Asociar Tags a productos"),
    },

  ];

  const tableContent = (
    <div className={`configTag-list-content`}>
      {!isMobile && <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />}

      {configTags.length > 0 && (
        <div className={`configTag-list-table`}>
          <GenericTable
            items={configTagsTable}
            totalItems={pager.count}
            columns={columns}
            handleClick={goToDetail}
          />
          {!isMobile && (
            <div className="table-pagination">
              <Pagination
                pageQuantity={pager.countPages}
                currentPage={page}
                pageChangeHandler={handlePagerChange}
              />
            </div>
          )}
        </div>
      )}
      {configTags.length === 0 && isSearched && (
        <NotFoundItems />
      )}
    </div>
  );

  const noconfigTagscontent = (
    <div className="configTag-no-configTags-content">
      <img src={NodeImg} />
      <div className="configTag-no-configTags-title">
        {i18n.t("Aún no tienes plantillas de tags. ¡Crea tu primer plantilla!")}
      </div>
      <div className="configTag-create-button" onClick={createConfigTag}>
        CREAR
      </div>
    </div>
  );
  const productsElement = noItems ? noconfigTagscontent : tableContent;
  // const productsElement =  noconfigTagscontent;

  const content = (
    <div className="table-configTags-outter-wrapper">
      {isMobile && <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />}
      <div className="table-configTags-wrapper">{productsElement}</div>
    </div>
  );

  const goToBack = () => {
    history.goBack();
 }


  return (
    <div className="configTag-list-container">
      <AdminLayout
        headerTitle={i18n.t("Tags")}
        headerOptions={noItems ? [] : headerOptions}
        goBackFunc={goToBack}
        content={content}
        navHidden={false}

      ></AdminLayout>
      {loading && <Loader />}
      <MainNotification
        label={"Tu producto se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default ConfigTagListView;
