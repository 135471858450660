import Environment from "../environment";
import Api from "./ApiService";
import { AuthService } from "./AuthService.js";

export const MovementService = {
  movement: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/movements/`, "GET", params)
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createMovement: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/movements/`, "POST", data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateMovement: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/movements/${id}/`, "PATCH", data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteMovement: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/movements/${id}/`, "DELETE")
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeStatus: (id, status) => {
    return new Promise((resolve, reject) => {
      const data = {
        status,
      };
      return Api.fetch(
        `${Environment.api}api/movements/${id}/status/`,
        "PUT",
        data
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  items: (id, params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/movements/${id}/items/`,
        "GET",
        params
      )
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addItems: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/movements/${id}/items/`,
        "POST",
        data
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateItems: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/movements/${id}/items/`,
        "PUT",
        data
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteItems: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/movements/${id}/items/`,
        "DELETE",
        data
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  movementItemsHistory: (itemId, params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/movement_items/history/${itemId}/`,
        "GET",
        params
      )
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  movementItemsHistoryDownload: (itemId, params) => {
    return new Promise((resolve, reject) => {
      return Api.fetchDownload(
        `${Environment.api}api/movement_items/history/${itemId}/`,
        "GET",
        params
      )
        .then((blob) => {
          resolve(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
