import React from 'react';
import SearchInput from "components/MainApp/atoms/Filters/SearchSimple";

import "./styles.scss";

const SimpleSearchPanel = ({ placeholder, handleSubmit }) => {

    return <div className="simple-search-panel">
            <SearchInput placeholder={placeholder} handleSubmit={handleSubmit}/>
        </div>
}

export default SimpleSearchPanel;