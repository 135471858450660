import { toBigInt } from "helpers/utils/utils_general";

const getEpc = (movementItem) => {
  if (movementItem.tag) {
    return movementItem.tag.rfid;
  }
  if (movementItem.item_stock.tag) {
    return movementItem.item_stock.tag.rfid;
  }
  return "Sin Tag";
};
const getEpcNumber = (rfid) => {
  if(!rfid) return;

  const numericEPC = toBigInt(rfid);
  return numericEPC.toString(16);
}

const getBarcode = (movementItem) => {
  if (movementItem.tag) {
    return movementItem.tag.barcode;
  }
  if (movementItem.item_stock.tag) {
    return movementItem.item_stock.tag.barcode;
  }
  return null;
};

/**
 * Agrupa los items de movimiento por el tipo de Item, guardando por
 * cada uno el movementItem.id, tag y si se encuentra seleccionado.
 * Ademas si pasa orderItems se guarda cuantos items deberia haber por
 * cada tipo de Item
 * @param {Dict[]} movementItems Arreglo de items de movimento
 * @param {Dict[]} orderItems Arreglo de order items parseado por ItemHelper/parseItems()
 * @return {Dict[]} [ { title, sku, tags: [ {movement_item_id, tag, selected} ] } ]
 */
const itemsByItem = (movementItems, orderItems = null) => {
  let newList = [];
  let items = movementItems.sort((a, b) => {
    if (a.item_stock.item.sku < b.item_stock.item.sku) return -1;
    if (a.item_stock.item.sku > b.item_stock.item.sku) return 1;
    return 0;
  });

  let ind = 0;
  const cant = items.length;
  while (ind < cant) {
    let actual = items[ind];
    const rfid = getEpc(actual);
    let new_item = {
      title: actual.item_stock.item.name,
      sku: actual.item_stock.item.sku,
      expectedUnits: null,
      type: actual.item_stock.item.type,
      tags: [
        {
          movement_item_id: actual.id,
          tag: rfid,
          epc: getEpcNumber(rfid),
          barcode: getBarcode(actual),
          selected: false,
        },
      ],
    };

    if (orderItems !== null) {
      const order_item = orderItems.find(
        (i) => i.id === actual.item_stock.item.id
      );
      new_item.expectedUnits = order_item ? order_item.units : 0;
    }

    ind += 1;
    while (ind < cant && actual.item_stock.item.sku === new_item.sku) {
      actual = items[ind];
      if (actual.item_stock.item.sku === new_item.sku) {
        const rfid = getEpc(actual);
        new_item.tags.push({
          movement_item_id: actual.id,
          tag: rfid,
          epc: getEpcNumber(rfid),
          barcode: getBarcode(actual),
          selected: false,
        });
        ind += 1;
      }
    }

    newList.push(new_item);
  }

  return newList;
};

/**
 * Agrupa los items de movimiento por la locacion a la que van a ir, guardando por
 * cada uno el movementItem.id, y si se encuentra seleccionado.
 * Requiere que el movementItem tenga asociado una locacion.
 * @param {Dict[]} movementItems Arreglo de items de movmiento
 * @return {Dict[]} [ { title, code, dispatch_area, items: [ {movement_item_id, selected} ] } ]
 */
const itemsByLocation = (movementItems) => {
  let newList = [];
  let items = movementItems.sort((a, b) => {
    if (a.location.id < b.location.id) return -1;
    if (a.location.id > b.location.id) return 1;
    return 0;
  });

  let ind = 0;
  const cant = items.length;
  while (ind < cant) {
    let actual = items[ind];
    let new_item = {
      title: actual.location.path_name,
      code: actual.location.code,
      idLocation: actual.location.id,
      dispatch_area: actual.location.dispatch_area,
      items: [
        {
          movement_item_id: actual.id,
          tag: actual.tag.rfid,
          selected: false,
        },
      ],
    };

    ind += 1;
    while (ind < cant && actual.location.id === new_item.idLocation) {
      actual = items[ind];
      if (actual.location.id === new_item.idLocation) {
        new_item.items.push({
          movement_item_id: actual.id,
          tag: actual.tag.rfid,
          selected: false,
        });
        ind += 1;
      }
    }

    newList.push(new_item);
  }

  return newList;
};

/**
 * Busca en un array generado por itemsByItem() los items que tengan
 * selected TRUE.
 * @param {Dict[]} items Arreglo generado por itemsByItem()
 * @return {Dict[]} [ {movement_item_id, selected} ]
 */
const getSelectedItems = (items) => {
  const selecteds = [];

  for (let item of items) {
    for (let item_tag of item.tags) {
      if (item_tag.selected) {
        item_tag["type"] = item.type;
        selecteds.push(item_tag);
      }
    }
  }

  return selecteds;
};

export { itemsByItem, itemsByLocation, getSelectedItems };
