import Environment from "../environment";
import Api from './ApiService';
import { AuthService } from './AuthService.js';

import {
    Order,
    RequestOrder,
    PrepOrder,
    ReceptionOrder,
    RoadMapMovement,
    RelocationOrder
} from "../models/Orders";


export const OrderService = {
	orders: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/orders/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Order(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    summaryOrder: (params) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/summary/orders/`, 'GET', params)
                .then(rta => {
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    createOrder: (data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/orders/`, 'POST', data
                ).then(data => {
                    resolve(new RequestOrder(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateOrder: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/orders/${id}/`, 'PUT', data
                ).then(data => {
                    resolve(new Order(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    addItems: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/orders/${id}/items/`, 'POST', data
                ).then(data => {
                    resolve(new RequestOrder(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    getItems: (id, node = '') => {
        node = node || AuthService.getCurrentNodeCode();
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${node}/orders/${id}/items/`, 'GET'
                ).then(data => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    roadMap: (id, node = '') => {
        node = node || AuthService.getCurrentNodeCode();
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${node}/orders/${id}/road-map/`, 'GET'
                ).then(rta => {
                	rta = rta.map(item => new RoadMapMovement(item));
                    resolve(rta)
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    addMovement: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/orders/${id}/road-map/`, 'POST', data
                ).then(data => {
                    resolve(new RoadMapMovement(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    changeOrderStatus: (id, status, reason) => {
        const data = {
            status,
            reason
        }
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/orders/${id}/status/`, 'POST', data
                ).then(data => {
                    resolve(new RequestOrder(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    // Orders name
    ordersCode: (params) => {
        return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/search/orders/`, 'GET', params);
    },

    // REQUEST ORDER
    requestOrders: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/request_orders/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Order(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    requestOrder: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/request_orders/${id}/`, 'GET')
                .then(rta => {
                	rta = new RequestOrder(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    requestDownloadVoucher: (id) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/request_orders/${id}/voucher/`
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    requestDownloadReport: (params) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/request_orders/report/`, 'GET', params
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    // PREP ORDER
    prepOrders: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/prep_orders/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new PrepOrder(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    prepOrder: (id, node = '') => {
      node = node || AuthService.getCurrentNodeCode();
		  return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${node}/prep_orders/${id}/`, 'GET')
                .then(rta => {
                	rta = new PrepOrder(rta);
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    prepDownloadVoucher: (id) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/prep_orders/${id}/voucher/`
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    prepDownloadReport: (params) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/prep_orders/report/`, 'GET', params
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    // RECEPTION ORDER
    recOrders: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/reception_orders/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new ReceptionOrder(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    recOrder: (id, node = '') => {
      node = node || AuthService.getCurrentNodeCode();
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${node}/reception_orders/${id}/`, 'GET')
                .then(rta => {
                	rta = new ReceptionOrder(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    recDownloadVoucher: (id) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/reception_orders/${id}/voucher/`
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    receptionDownloadReport: (params) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/reception_orders/report/`, 'GET', params
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    // RELOCATION ORDER
    relocationOrder: (id) => {
      return new Promise((resolve, reject) => {
        return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/relocation_orders/${id}/`, 'GET')
          .then(rta => {
                    rta = new RelocationOrder(rta)
                      resolve(rta)
                  }).catch(error => {
                      reject(error);
                  });
          });
    },

    relocationOrders: (params) => {
      return new Promise((resolve, reject) => {
        return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/relocation_orders/`, 'GET', params)
          .then(rta => {
            rta.results = rta.results.map(item => new Order(item));
            resolve(rta)
          }).catch(error => {
            reject(error);
          });
      });
    },

    relocationDownloadReport: (params) => {
      return new Promise((resolve, reject) => {
        return Api.fetchDownload(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/relocation_orders/report/`, 'GET', params
          ).then(blob => {
            resolve(blob);
          }).catch((error) => {
            reject(error);
          });
      });
    },

    relocationDownloadVoucher: (id) => {
      return new Promise((resolve, reject) => {
        return Api.fetchDownload(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/relocation_orders/${id}/voucher/`
          ).then(blob => {
            resolve(blob);
          }).catch((error) => {
            reject(error);
          });
      });
    },
}
