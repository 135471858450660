import Environment from "../environment";
import Api from "./ApiService";

import { Device } from "../models/Device";

export const DeviceService = {

  readersDevices: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/devices/readers/?no_page=1`,
        "GET",
        params
      )
        .then((rta) => {
          rta = rta.map((item) => new Device(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  devices: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/devices/?no_page=1`,
        "GET",
        params
      )
        .then((rta) => {
          rta = rta.map((item) => new Device(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  devicesPaginated: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/devices/`,
        "GET",
        params
      )
        .then((rta) => {
          rta.results = rta.results.map((item) => new Device(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  device: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/devices/${id}/`, "GET")
        .then((rta) => {
          rta = new Device(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createDevice: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetchUpload(`${Environment.api}api/devices/`, "POST", data)
        .then((data) => {
          resolve(new Device(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateDevice: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetchUpload(`${Environment.api}api/devices/${id}/`, "PATCH", data)
        .then((data) => {
          resolve(new Device(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteDevice: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/devices/${id}/`, "DELETE")
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  startRead: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/devices/start_read/`,
        "POST",
        data
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  stopRead: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/devices/stop_read/`, "POST", data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};
