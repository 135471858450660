import React from "react";
import ItemStockReadingTableItem from "./ItemStock";
import Icon from 'components/MainApp/atoms/Icon/icon';
import "./styles.scss";
const ItemStockReadingTable = ({
  stock,
  headers,
  headerClickHandler,
  handleClickProduct,
  tagsInMov = [],
}) => {
  const renderHeaderItem = (key) => {
    return (
      <div
        data-automation-id={`sort-by-${key}`}
        onClick={() => {
          headerClickHandler(key);
        }}
        data-filter-name={key}
        className={`stock-reading-table-header-item stock-reading-table-header-${key}`}
      >
        {headers[key].title}
        <span className={`stock-reading-table-header-icon-span ${headers[key].active ? "active" : ""}  ${headers[key].direction ? "" : "down"}`}> <i className="icon-iconos_flecha-ordenar"></i></span>
      </div>
    );
  };
  const table = (
    <React.Fragment>
      <div className="stock-reading-table-desktop" data-automation-id="stock-table">
        <div className="stock-reading-table-header">
          {Object.keys(headers).map((key) => renderHeaderItem(key))}

          <div className="stock-reading-table-header-item stock-reading-table-header-button"></div>
        </div>
        <div className="stock-reading-table-content">
          {stock?.map((stockItem) => {
            return (
              <div className="stock-reading-table-content-item" key={stockItem.id}>
                <ItemStockReadingTableItem
                  stock={stockItem}
                  list={stockItem.tags}
                  tagsInMov = {tagsInMov}
                />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );

  const noitems = <div className={'stock-reading-table-no-items'}>
      <Icon
          name="stock"
          fill="#3C7AF5"
          width={87}
          height={65}
        ></Icon>
    <h1>Aún no tienes resultado</h1></div>

  return <div className="stock-reading-table-container">
      {stock.length > 0 ? table : noitems}
    </div>;
};

export default ItemStockReadingTable;
