import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";
import FileSaver from "file-saver";

import Environment from "environment";

import { StockService } from "services/StockService";
// import { DeviceService } from "services/DeviceService";
import { stockByItem } from "helpers/stock/StockHelper";

import { WSService } from "services/WSServices";

import useRequest from "hooks/useRequest";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import AuditoriaFilterPanel from "./AuditoriaFilterPanel";
import ReadingPanel from "./ReadingPanel";
import ItemStockReadingTable from "./ItemStockReadingTable";
import Loader from "components/MainApp/atoms/Loader";
import ErrorModal from "components/MainApp/organisms/Stock/ErrorModalAuditory";

import excel from "assets/images/excel.svg";
import excelwhite from "assets/images/excelwhite.svg";

import "./styles.scss";
import Tooltip from "components/MainApp/atoms/Tooltip";
import { LocationService } from "services/LocationService";
import { toBigInt } from "helpers/utils/utils_general";
import { isMobile } from "helpers/Mobile";

const headers = {
  product: {
    title: "PRODUCTO",
    active: true,
    direction: false,
  },
  sku: {
    title: "SKU",
    active: false,
    direction: false,
  },
  tags: {
    title: "TAGS",
    active: false,
    direction: false,
  },
};

let globalStock = [];

const AuditoriaDeStock = () => {
  const history = useHistory();
  const headerOptions = [];

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();
  const [enabledRead, setEnabledRead] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [mobilePanelOpened, setMobilePanelOpened] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [originalStock, setOrginalStock] = useState([]);

  const [groupedStock, setGroupedStock] = useState([]);
  const [tagsInMov, setTagsInMov] = useState([]);
  const [validatedItems, setValidatedItems] = useState(0);
  const [invalidTagsCount, setInvalidTagsCount] = useState(0);
  const [msgTooltip, setMsgToolTip] = useState("Selecciona los filtros");
  const [selectedCombo, setSelectedCombo] = useState(null);
  const [tooltipStyle, setTooltipStyle] = useState("0.5");
  const [optionsChanged, setOptionsChanged] = useState("");

  const search = (selectedLoc) => {
    if (!selectedLoc) return;

    if (selectedLoc.count == 0) {
      clearGrid();
      return;
    }

    setEnabledRead(false);

    beforeSubmit();
    if (selectedLoc.count > 1) {
      let locations = [];
      selectedLoc.results.forEach(location => {
        locations.push(location.id)
      });
      StockService.stock({
        no_page: 1,
        status: "LOC",
        locations: locations,
      })
        .then((res) => {
          setData(res);
        })
        .catch((_) => {
          afterSubmit();
        });
    } else {
      StockService.stock({
        no_page: 1,
        status: "LOC",
        location: selectedLoc?.id ?? selectedLoc.results[0]?.id,
      })
        .then((res) => {
          setData(res);
        })
        .catch((_) => {
          afterSubmit();
        });
    }

  }

  const setData = (res) => {
    afterSubmit();
    const _groupedStock = stockByItem(res);

    // Buscar los tags en ordenes en proceso (IN_MOV)
    StockService.rfids_in_mov_orders(res.map((stock) => stock.tag.rfid)).then((rfids) => {
      const tag_rfids = rfids.map((rfid) => rfid?.tag?.rfid);
      setTagsInMov(tag_rfids);
    });
    setOrginalStock(res);
    setGroupedStock(_groupedStock);
    setValidatedItems(0);
    setInvalidTagsCount(0);
    globalStock = _groupedStock;
    setEnabledRead(true);
    if (res.length > 0) {
      setMsgToolTip("Generar reporte");
      setTooltipStyle("100");
    }else{
      setMsgToolTip("No hay stock disponible para generar reporte");
      setTooltipStyle("0.5");
    }
  }

  const receiveEvent = useCallback((payload) => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);
    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      handleTagSearch(payload.params.epc, payload.params);
    }
  }, []);

  const setErrorModal = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const clearGrid = () => {
    setOrginalStock([]);
    setGroupedStock([]);
    setInvalidTagsCount(0);
    setValidatedItems(0);
  }

  const handleApplyFilters = (selectedOptionsNew) => {
    setSelectedOptions(selectedOptionsNew);

    handleClear();

    if (selectedOptionsNew[1] &&  Object.keys(selectedOptionsNew[1]).length > 0) {

      if (selectedOptionsNew.length > 0) {
        setMobilePanelOpened(false);
      }
      var location = {};
      if (Object.keys(selectedOptionsNew[2]).length > 0 ) {
        setSelectedLocation(selectedOptionsNew[2]);
        location = selectedOptionsNew[2];
      } else if (Object.keys(selectedOptionsNew[1]).length > 0 ) {
        setSelectedLocation(selectedOptionsNew[1]);
        location = selectedOptionsNew[1];
      }

      search(location);
    } else {
      if(selectedOptionsNew.length === 0) return;
      // only branch office
      LocationService.locations({
        code: selectedOptionsNew[0].id,
        no_parent: "true",
        parent: null,
      }).then((locations) => {
        if (locations.count > 0) {
          setMobilePanelOpened(false);
        }
        setSelectedLocation(locations);
        search(locations);
      });
    }
  };

  const handleClear = (event) => {
    const _groupedStock = stockByItem(originalStock);
    setGroupedStock(_groupedStock);
    setValidatedItems(0);
    setInvalidTagsCount(0);
    globalStock = _groupedStock;
  };
  const handleDelete = (event) => {
    clearGrid();
  };

  const handleTagSearch = (cod, data = null) => {
    if (!cod) return;

    let found = false;
    let total = 0;
    // Buscar EPC
    globalStock.forEach((i, index, arr) => {
      i.tags.forEach((t, indexTag) => {
        if (t.tag === cod) {
          arr[index]["tags"][indexTag]["selected"] = true;
          found = true;
        }
      });

      i.readItems = i.tags.filter((t) => ((t.selected === true) && (t.message === null))).length;
      total += i.tags.filter((t) => ((t.selected === true) && (t.message !== null))).length;
    });
    setInvalidTagsCount(total);
    if (found) {
      setGroupedStock(globalStock);
    }

    if (!found && data) {
      addInvalidTag(data);
    }

    // Calcular cantidad validados
    let cant = 0;
    globalStock.forEach((i) => {
      i.tags.forEach((t) => {
        if ((t.selected) && (t.message === null)) cant++;
      });
    });
    setValidatedItems(cant);
  };

  const addInvalidTag = (data) => {
    let total = 0;
    let tag = {
      tag: "",
      epc: "",
      barcode: "",
      selected: true,
      message: null,
    };
    let item = {
      title: "-- Solo Tag --",
      sku: "",
      image: null,
    };
    if (data.stock) {
      item.title = data.stock.item.name;
      item.sku = data.stock.item.sku;
      item.image = data.stock.item.image;
      tag.tag = data.stock.tag.rfid;
      const numericEPC = toBigInt(data.stock.tag.rfid);
      tag.epc = numericEPC.toString(16);
      tag.barcode = data.stock.tag.barcode;
      if ([].includes(data.stock.status)) {
        tag.message = i18n.t("El stock se encuentra en transicion");
      } else {
        const location = data.stock.location.node.name + " | " + data.stock.location.path_name;
        tag.message = i18n.t("El stock se encuentra en ") + location;
      }
    } else if (data.tag) {
      item.sku = data.tag.sku;
      tag.tag = data.tag.rfid;
      const numericEPC = toBigInt(data.tag.rfid);
      tag.epc = numericEPC.toString(16);
      tag.barcode = data.tag.barcode;
      tag.message = i18n.t("El tag no se encuentra asociado a ningun producto");
    } else {
      item.title = "-- Solo EPC --";
      item.sku = "NO_ASOCIADO";
      tag.tag = data.epc;
      const numericEPC = toBigInt(data.epc);
      tag.epc = numericEPC.toString(16);
      tag.message = i18n.t("El tag no se encuentra en el sistema");
    }

    let found = false;
    globalStock.forEach((i, index, arr) => {
      if (i.sku === item.sku) {
        i.tags.push(tag);
        i.readItems = i.tags.filter((t) => (t.selected && t.message === null)).length;
        total += i.tags.filter((t) => ((t.selected === true) && (t.message !== null))).length;
        if (i.title === "-- Solo Tag --") {
          i.title = item.title;
        }
        found = true;
      }
    });

    if (!found) {
      total++;
      globalStock.push({
        title: item.title,
        sku: item.sku,
        image: item.image,
        expectedUnits: 0,
        readItems: 1,
        tags: [tag],
      });
    }
    setInvalidTagsCount(total);
    setGroupedStock(globalStock);
  };


  const downloadReport = () => {
    if (!selectedLocation || tooltipStyle == '0.5') {
      return;
    }

    const items = globalStock.map((i) => {
      return {
        sku: i.sku,
        stock: i.expectedUnits,
        readed: i.tags.filter((s) => s.selected).length,
      };
    });

    beforeSubmit();

    let loc = selectedLocation.id;
    if (selectedLocation.count > 1) {
      loc = selectedLocation.results.map((n) => n.id);
    }else{
      loc = selectedLocation.id;
    }

    StockService.downloadAuditoryReport(loc, items)
      .then((blob) => {
        afterSubmit();
        FileSaver.saveAs(blob, "reporte.xls");
      })
      .catch((error) => {
        afterSubmit();
      });
  };

  const handleSelectionChange = (selected) => {
    setSelectedCombo(selected);
    if(selectedCombo === selected) return;
    handleDelete();
  }

  const content = (
    <div className={"auditoria-de-stock-content"}>
      <div className={"auditoria-de-stock-filter-panel"}>
        <AuditoriaFilterPanel
          handleApplyProp={handleApplyFilters}
          handleDeleteProp={handleDelete}
          opened={
            mobilePanelOpened ||
            !selectedOptions ||
            selectedOptions.length === 0
          }
          handleOpen={() => setMobilePanelOpened(!mobilePanelOpened)}
          handleSelectionChange={handleSelectionChange}
        />
      </div>
      {(!isMobile || !mobilePanelOpened) && (
        <>
          <div className={"auditoria-de-stock-reading-panel"}>
            <ReadingPanel
              read={validatedItems}
              total={originalStock.length}
              handleSearch={handleTagSearch}
              handleClear={handleClear}
              enabledRead={enabledRead}
              typeRead={"STOCK"}
              refRead={""}
              receiveEvent={receiveEvent}
            />
          </div>
          <div className={"auditoria-de-stock-table"}>
            <div className="auditoria-de-stock-table-header">
              {!isMobile && originalStock.length > 0 && (
                <div className={"auditoria-de-stock-table-header-count"}>
                  Stock disponible <span>({originalStock.length})</span>
                </div>
              )}
              {invalidTagsCount > 0 && (
                <div className={"auditoria-de-stock-table-header-invalid-tags"}>
                  Tag erroneo <span>({invalidTagsCount})</span>
                </div>
              )}
            </div>
            {groupedStock.length > 0 && (
              <ItemStockReadingTable stock={groupedStock} headers={headers} tagsInMov={tagsInMov} />
            )}
          </div>{" "}
        </>
      )}
    </div>
  );

  const headerButtons = (
    <Tooltip
      content={
        <div
          className="excel-button"
          onClick={downloadReport}
          style={{
            width: "30px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            opacity: tooltipStyle,
          }}
        >
          <img style={{ height: "30px" }} src={isMobile ? excelwhite : excel} />
        </div>
      }
      text={msgTooltip}
    ></Tooltip>
  );

  return (
    <div className="auditoria-de-stock-container">
      <AdminLayout
        headerTitle={i18n.t("Auditoría de Stock")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={() => history.push("/admin/stock")}
        customButtons={headerButtons}
      ></AdminLayout>

      <ErrorModal
        open={openErrorModal}
        message={errorMessage}
        handleGoBack={(e) => {
          setOpenErrorModal(false);
        }}
        handleClose={(e) => {
          setOpenErrorModal(false);
        }}
        handleButtonClick={(e) => {
          setOpenErrorModal(false);
        }}
      />

      {loading && <Loader />}
    </div>
  );
};

export default AuditoriaDeStock;
