import Environment from "../environment";
import Api from "./ApiService";

import { ConfigTag, Tag } from "../models/Tag";
import { Item } from "models/Items";

export const TagService = {
  configTags: () => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/configs_tag/`, "GET")
        .then((rta) => {
          rta = rta.results.map((item) => new ConfigTag(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  configTagsPaginated: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/configs_tag/`, "GET", params)
        .then((rta) => {
          rta.results = rta.results.map((item) => new ConfigTag(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  configTag: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/configs_tag/${id}/`, "GET")
        .then((rta) => {
          rta = new ConfigTag(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createConfigTag: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/configs_tag/`, "POST", data)
        .then((data) => {
          resolve(new ConfigTag(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateConfigTag: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/configs_tag/${id}/`,
        "PATCH",
        data
      )
        .then((data) => {
          resolve(new ConfigTag(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteConfigTag: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/configs_tag/${id}/`, "DELETE")
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  generateTags: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/configs_tag/${id}/generate/`,
        "POST",
        data
      )
        .then((rta) => {
          rta = rta.map((item) => new Tag(item));

          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //
  // TAGS
  //

  itemFromTag: (epc) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/tags/${epc}/item/`, "GET")
        .then((rta) => {
          rta = new Item(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  countByTag: (epc) => {
    const data = {
      rfid: epc
    }

    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/tags/${epc}/count_item`, "GET", data)
        .then((rta) => {
          rta = new Item(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  countByTags: (epcs) => {
    const rfids = epcs.map((epc) => epc.tag);

    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/tags/count_items?rfid=${rfids.join('&rfid=')}`, "GET")
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  tag: (rfid) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/tags/${rfid}/`, "GET")
        .then((rta) => {
          rta = new Tag(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateTag: (rfid, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/tags/${rfid}/`,
        "PATCH",
        data
      )
        .then((data) => {
          resolve(new ConfigTag(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createTag: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/tags/`,"POST",data )
        .then((data) => {
          resolve(new ConfigTag(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeTags: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/tags/remove/`, "DELETE", data)
        .then((_) => {
          resolve({});
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  printTags: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/tags/print/`, "POST", data)
        .then((data) => {
          resolve(new Tag(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
