import React, { useState, useEffect } from "react";

import usePagination from "hooks/usePagination";
import { isMobile } from "helpers/Mobile";

import InputText from "components/MainApp/atoms/Forms/MainInput";
import ItemLocalization from "components/MainApp/organisms/Stock/ItemLocalization";
import Toggle from "components/MainApp/atoms/Forms/Toggle";
import Pagination from "components/MainApp/atoms/Pagination";

import DeviceSelector from "components/Functionals/DeviceSelector";

import "./styles.scss";

export const LocalizationItems = ({
  enabledRead,
  typeRead,
  refRead,
  receiveEvent,
  groupItems = [],
  groupSimilarItems = [],
  total,
  handleSimilarStatus,
  similar,
  handleSearch,
  handleDelete,
  handleDeleteAll,
  onDeviceError
}) => {
  const [searchedValue, setsearchedValue] = useState("");

  const [
    list,
    currentPage,
    pageSize,
    pageList,
    pageQuantity,
    changePageSize,
    changePage,
    changeList
  ] = usePagination([], 1, 3);

  useEffect(() => {
    changeList(groupItems, currentPage, pageSize);
  }, [groupItems]);

  const searchValue = e => {
    setsearchedValue(e.target.value.trim());
  };

  const handleSubmit = function(e) {
    e.preventDefault();
    handleSearch(searchedValue);
    setsearchedValue("");
  };

  return (
    <div className={"localization-items-outter"}>
      <div className="localization-items-wrapper">
        <DeviceSelector
          enabled={enabledRead}
          typeRead={typeRead}
          refRead={refRead}
          receiveEvent={receiveEvent}
          onDeviceError={onDeviceError}
        />
        <div className="localization-items-content">
          <div className="localization-items-input-wrapper">
            <div className="localization-items-input-label">
              Ingresa el nro. o escanea TagID
            </div>
            <form className="localization-items-input" onSubmit={handleSubmit}>
              <InputText
                label="Ingresa número"
                handleChange={searchValue}
                value={searchedValue}
              />
            </form>
          </div>
          <div className="localization-items-bottom">
            <div className="localization-items-toggle similars">
              <p>Mostrar productos similares</p>
              <Toggle
                handleChange={handleSimilarStatus}
                checked={similar}
                name="similar"
              />
            </div>
            {!isMobile && groupItems.length > 0 && (
              <div className="localization-items-buttons-wrapper">
                <div
                  className="localization-items-button localization-items-clean"
                  onClick={handleDeleteAll}
                >
                  LIMPIAR
                </div>
              </div>
            )}
          </div>

          {groupItems.length > 0 && (
            <div className="localization-items-count">{total}</div>
          )}
        </div>
        {groupItems.length > 0 && (
          <div className="localization-items-content">
            <div className="localization-items-list">
              {pageList.map(item => {
                return (
                  <ItemLocalization
                    key={`${item.sku}-${item.loc_id}`}
                    item={item}
                    quantity={item.tags.length}
                  />
                );
              })}
            </div>

            {!similar && groupItems.length > 0 && (
              <div>
                <Pagination
                  pageQuantity={pageQuantity}
                  currentPage={currentPage}
                  pageChangeHandler={changePage}
                />
              </div>
            )}
          </div>
        )}
        {similar && groupItems.length > 0 && (
          <div className="localization-items-content">
            <h3 className="localization-items-similar-title">
              Productos similares
            </h3>
            <div className="localization-items-list">
              {groupSimilarItems.map(item => {
                return (
                  <ItemLocalization
                    key={`${item.sku}-${item.loc_id}`}
                    item={item}
                    quantity={item.tags.length}
                  />
                );
              })}
            </div>
          </div>
        )}

        {groupItems.length > 0 && isMobile && (
          <div className="localization-items-content">
            <div className="localization-items-buttons-wrapper">
              <div
                className="localization-items-button localization-items-clean"
                onClick={handleDeleteAll}
              >
                {" "}
                LIMPIAR{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocalizationItems;
