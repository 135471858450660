import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import { ProviderService } from "services/ProviderService";

import useRequest from "hooks/useRequest";
import { isMobile } from "helpers/Mobile";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";

import MainNotification from "components/MainApp/atoms/Notification";
import FilterPanel from "components/MainApp/organisms/Filters/FilterPanel";
import GenericTable from "components/MainApp/organisms/GenericTable";
import Pagination from "components/MainApp/atoms/Pagination";

import NodeImg from "./storefront-24px.svg";
import columns from "./columns";

import "./styles.scss";
import {PublicService} from "../../../services/PublicService";
import { NotFoundItems } from "../components/NotFoundItems";
import useFilters from "hooks/useFilters";
import { set } from "date-fns";

let scrolling = false;

const initialFilters = {
  page_size: 10,
  search: "",
  ordering: "-entry_date",
  enabled: undefined
};
const filterTypeMap = {
  "Estado del proveedor": {
    name: "enabled",
    type: "twoOption",
    default_value: undefined
  },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "-entry_date"
  }
};
const filterDataSource = [
  {
    type: "twoOption",
    options: [
      {
        selected: false,
        label: "Habilitado",
        value: true
      },
      {
        selected: false,
        label: "Inhabilitado",
        value: false
      }
    ],
    label: "Estado del proveedor",
    firstOptionLabel: "Todos",
    name: "enabled"
  }
];

export const ProviderListView = () => {
  const history = useHistory();
  const [noProviders, setnoProviders] = useState(false);
  const [providers, setproviders] = useState([]);
  const [providerTable, setproviderTable] = useState([]);
  const [isSearched, setSearched] = useState(false);
  const [filterData, setFilterData] = useState(filterDataSource);
  const [providersFilters, setProvidersFilters] = useState();
  const [changedFilter, setChangedFilter] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isFirstRun = useRef(true);
  const [pager, setPager] = useState({
    next: null,
    previous: null,
    count: 0,
    countPages: 0,
  });
  const [page, setPage] = useState(1);
  let pageSize = 10;

  const { loading, beforeSubmit, afterSubmit } = useRequest();

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    // page,
    // setPage,
    items,
    setItems,
    // pager,
    // setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen
  ] = useFilters(
    initialFilters,
    providersFilters,
    filterTypeMap,
    isMobile,
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  useEffect(() => {
    fillFilterData();
  }, []);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    let firstRun = false;
    if (isFirstRun.current) {
      firstRun = true;
      isFirstRun.current = false;
    }

    filter({ page: page, page_size: pageSize }, firstRun);
  }, [page]);

  useEffect(() => {
    filter(false);

    setSearched(false);
    setChangedFilter(true);
    setProvidersFilters({
      filters: filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType
    });
    setMobilePanelOpen(false);
  }, [filters, page]);

  const filter = (params, first = false) => {
    let append = isMobile;
    if (!append) {
      setItems([]);
    }

    beforeSubmit();
    const data = { ...filters, page: page };
    ProviderService.providersPaginated(data)
      .then((response) => {

        let newProviders =
          isMobile && page !== 1
            ? [...providers, ...response.results]
            : response.results;

        if(isMobile && changedFilter) {
          newProviders = response.results;
          setChangedFilter(false);
        }

        if (first && newProviders.length < 1) setnoProviders(true);

        const _ = require('lodash');

        let countriesPromise = _.memoize(() => PublicService.countries());
        let regionsPromise = _.memoize((country) => PublicService.regions(country));
        let statesPromise = _.memoize((country, region) => PublicService.states(country, region));
        let citiesPromise = _.memoize((country, region, state) => PublicService.cities(country, region, state));

        let providerLocationDataPromises = [];
        newProviders = newProviders.map((provider) => {
          providerLocationDataPromises.push(
            new Promise((resolve, reject) => {
              Promise.all([
                countriesPromise(),
                regionsPromise(provider.country),
                statesPromise(provider.country, provider.region),
                citiesPromise(provider.country, provider.region, provider.state),
              ]).then((responses) => {
                provider.country = responses[0].find((c) => c.value === provider.country);
                provider.locality = responses[3].find((c) => c.value === provider.locality);
                provider.region = responses[1].find((c) => c.value === provider.region);
                provider.state = responses[2].find((c) => c.value === provider.state);
                resolve(provider);
              })
            })
          );
        });
        Promise.all(providerLocationDataPromises).then((processedProviders) => {
          afterSubmit();
          if(isMobile && params.page !== 1) {
            processedProviders = [...providers, ...processedProviders];
          }
          setproviders(processedProviders);
          const table = processedProviders.map((provider) => provider.getGenericTableView());
          setproviderTable(table);
          setPager({
            next: response.next,
            previous: response.previous,
            count: response.count,
            countPages: Math.ceil(response.count / pageSize),
          });
          scrolling = false;
          setSearched(true);
        });
      })
      .catch((_) => {
        afterSubmit();
        setSearched(true);
        scrolling = false;
      });
  };

  const goBack = () => {
    history.push("/admin/more-options/1");
  };

  const handleScroll = (event) => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || loading || scrolling) return;
    scrolling = true;
    setPage(page + 1);
  };

  const fillFilterData = function() {
    let filterDataAux = [...filterData];
    filterDataAux[0].options = filterDataAux[0].options.map(option => {
      option.selected = false;
      return option;
    });

    if (isMobile) {
      filterDataAux[1] = {
        type: "twoOption",
        options: [
          {
            selected: false,
            label: "Menos reciente",
            value: "entry_date"
          }
        ],
        label: "Ordenar por",
        firstOptionLabel: "Más reciente",
        value: "-entry_date"
      };
    }
    setFilterData(filterDataAux);
  };

  const handlePagerChange = function(page) {
    setPage(page);
  };

  const createProvider = () => {
    history.push(`/admin/providers/add`);
  };

  const goToProvider = (code) => {
    history.push(`/admin/providers/detail/${code}`);
  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: createProvider,
      tooltip: "Agregar proveedor",
    },
  ];

  const tableContentDesktop = (
    <React.Fragment>
      <div className={`provider-list-content`}>
        <FilterPanel
          resultList={[]}
          placeholder="Buscar"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
        ></FilterPanel>
        {providers.length > 0 && (
          <div className={`provider-list-table`}>
            <GenericTable
              items={providerTable}
              totalItems={pager.count}
              columns={columns}
              handleClick={goToProvider}
            />
            {!isMobile && (
              <div className="table-pagination">
                <Pagination
                  pageQuantity={pager.countPages}
                  currentPage={page}
                  pageChangeHandler={handlePagerChange}
                />
              </div>
            )}
          </div>
        )}
        {providers.length === 0 && isSearched && (
          <NotFoundItems />
        )}
      </div>
    </React.Fragment>
  );

  const tableContentMobile = (
    <React.Fragment>
      <div className="provider-table-filter-container">
        <SimpleSearchPanel
          placeholder="Busca por nombre"
          handleSubmit={handleSearch}
        />
      </div>
      <div
        className={`provider-table-mobile-panel ${
          mobilePanelOpen ? "opened" : ""
        } `}
      >
        <FilterPanel
          resultList={[]}
          placeholder="Buscar"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
          closeMobilePanel={() => {
            setMobilePanelOpen(false);
          }}
        ></FilterPanel>
        </div>
      <div className={`provider-table-table`}>
        <div className="provider-table-top-table">
          <div className="provider-table-top-total">
            Total{" "}
            <span className="provider-table-top-total-bold">({pager.count})</span>
          </div>
          <div
            className={`provider-table-top-sort ${
              applied ? "provider-table-active-filter" : ""
            }`}
            onClick={() => {
              setMobilePanelOpen(true);
            }}
          >
            <i className="icon-iconos_filtros provider-table-top-icon"></i>
          </div>
        </div>
        {providers.length > 0 && (
          <div className={`provider-list-table`}>
            <GenericTable
              items={providerTable}
              // totalItems={pager.count}
              columns={columns}
              handleClick={goToProvider}
            />
            {!isMobile && (
              <div className="table-pagination">
                <Pagination
                  pageQuantity={pager.countPages}
                  currentPage={page}
                  pageChangeHandler={handlePagerChange}
                />
              </div>
            )}
          </div>
        )}
        {providers.length === 0 && isSearched && (
          <NotFoundItems />
        )}
      </div>
    </React.Fragment>
  );

  const tableContent = isMobile ? tableContentMobile : tableContentDesktop;

  const noProviderContent = (
    <div className="provider-no-provider-content">
      <img src={NodeImg} />
      <div className="provider-no-provider-title">
        Aún no tienes proveedores creados. ¡Crea tu primer proveedor!
      </div>
      <div className="provider-create-button" onClick={createProvider}>
        CREAR
      </div>
    </div>
  );
  const providersElement = noProviders ? noProviderContent : tableContent;

  const content = (
    <div className="table-provider-wrapper">
      <div className="provider-table-inner">{providersElement}</div>
    </div>
  );

  return (
    <div className="provider-list-container">
      <AdminLayout
        headerTitle={i18n.t("Proveedores")}
        headerOptions={noProviders ? [] : headerOptions}
        content={content}
        goBackFunc={goBack}
        navHidden={false}
      ></AdminLayout>
      {loading && <Loader />}
      <MainNotification
        label={"Tu proveedor se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default ProviderListView;
