import React from 'react';
import ReactDOM from 'react-dom';

import "./i18n";

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Router } from 'react-router-dom';
import history from "./history";
import { Experimental_CssVarsProvider, experimental_extendTheme } from '@mui/material';

const theme = experimental_extendTheme({
	colorSchemes: {
		light: {
			palette: {
				primary: {
					main: "#3c7af5",
				},
			},
		},
		dark: {
			palette: {
				primary: {
					main: "#3c7af5",
				},
			},
		},
	},
});

ReactDOM.render(
	<Router history={history}>
		<Experimental_CssVarsProvider theme={theme}>
			<App />
		</Experimental_CssVarsProvider>
	</Router>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
