import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { DeviceService } from "services/DeviceService";
import { PublicService } from "services/PublicService";
import { LocationService } from "services/LocationService";
import { BusinessNodeService } from "services/BusinessNodeService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";

import DeviceForm from "./DeviceForm";
import "./styles.scss";
import { de } from "date-fns/locale";
import { DeviceTypeService } from "services/DeviceTypeService";
import { Device } from "models/Device";

const initialDevice = {
  id: null,
  serial_number: "",
  serial_number_old: "",
  port: null,
  device_type: "ANTENNA",
  name: "",
  enabled: true,
  device: null,
  node: null,
  location: null,
  port: "",
  device_type_id: null,
  item_file: null,
};

const titleMapping = {
  device: "Editar dispositivo",
  antenna_add: "Añadir antena",
  antenna_edit: "Editar antena",
};

const disabledFields = {
  antenna_add: ["node"],
  antenna_edit: ["port", "node"],
};

const EditDeviceView = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [device, setdevice] = useState(initialDevice);
  const [initSerialNumber, setInitSerialNumber] = useState('');

  const [type, settype] = useState(null);
  const [deviceTypeId, setDevicetypeId] = useState(null);
  const [parentDevice, setParentDevice] = useState();
  const [parentId, setparentId] = useState(null);

  const [businessnodes, setbusinessnodes] = useState([]);
  const [zones, setzones] = useState([]);
  const [sectors, setsectors] = useState([]);
  const [showSelectorFiles, setShowSelectorFiles] = useState(true);

  useEffect(() => {
    if (props.match.params.key) {
      beforeSubmit();
      const id = props.match.params.key;
      DeviceService.device(id)
        .then(async response => {
          completeDeviceAux(response, response.node)
            .then(data => {
              if (data.item_file?.file_name === null) {
                data.item_file = null;
              }
              setdevice(data);
              setInitSerialNumber(data.serial_number);
              setShowSelectorFiles(data.item_file === null);
              afterSubmit();
            })
            .catch(err => {
              afterSubmit();
            });

        })
        .catch((error) => {
          afterSubmit();
        });
    }

    PublicService.nodes().then((response) => {
      response = response.map((r) => {
        r.label = r.name;
        return r;
      });
      setbusinessnodes(response);
    });

    if (!props.location.state.type) return;

    const type = props.location.state.type;


    settype(type);
    const parent = props.location.state.parent;
    setparentId(parent);

    if (parent && !props.match.params.key) {
      beforeSubmit();
      DeviceService.device(parent)
        .then((response) => {
          afterSubmit();
          setParentDevice(response);
          let deviceAux = { ...device };
          deviceAux.serial_number = response.serial_number;

          completeDeviceAux(deviceAux, response.node).then(data => {
            setdevice(data);
          });
        })
        .catch((error) => {
          afterSubmit();
        });
    }
  }, []);

  const completeDeviceAux = async (deviceAux, node) => {
    node.label = node.name;
    deviceAux.node = node;

    let devices_types = await DeviceTypeService.device_types();
    let deviceType = devices_types.filter(t => t.type == initialDevice.device_type)[0];
    deviceAux.device_type = deviceType;

    const locations = await LocationService.locations({
      code: node.code,
      no_parent: "true",
      parent: null
    });

    if (locations) {
      const zone = mapZones(locations.results).find(
        zone => zone.id === deviceAux.location?.parent || zone.id === deviceAux.location?.id
      );

      if (!zone) return deviceAux;

      deviceAux.zone = zone;

      const sectors = await LocationService.locations({
        code: node.code,
        no_parent: "false",
        parent: zone.id
      });

      if (sectors) {
        const sector = mapSectors(sectors.results).find(
          sector => sector.id === deviceAux.location.id
        );

        if (!sector) return deviceAux;

        deviceAux.area = sector;
      }
    }

    return deviceAux;
  };

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const handleChangeImage = (e, err) => {
    const newFile = e.target.files[0];
    setdevice(prevDevice => {
      return { ...prevDevice, item_file: newFile };
    });
    setShowSelectorFiles(false);
  };

  const handleDeleteImage = (e) => {
    setdevice({ ...device, item_file: null });
    setShowSelectorFiles(true);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    beforeSubmit();

    let deviceAux = { ...device };
    deviceAux.node = deviceAux.node.code;
    if(!deviceAux.zone){
      deviceAux.location = null;
      deviceAux.location_id = null;
    }else if(deviceAux?.location?.id){
      deviceAux.location_id = deviceAux.location.id;
    }

    deviceAux.device_type_id = deviceAux.device_type?.id ?? '';

    if (parentId) {
      deviceAux.device = parentId;
    }
    if(device.item_file){
      deviceAux.item_file = device.item_file;
    }
    if (props.match.params.key) {
      deviceAux.serial_number_old = initSerialNumber;

      const formData = new FormData();
      if (deviceAux.item_file) {
        formData.append('item_file', deviceAux.item_file?.file_name ?? deviceAux.item_file);
      }
      for (let key in deviceAux) {
        if (key !== 'item_file') {
          formData.append(key, deviceAux[key]);
        }
      }

      if(formData.get('location') === 'null') formData.delete('location');
      if(formData.get('location_id') === 'null') formData.delete('location_id');

      DeviceService.updateDevice(props.match.params.key, formData)
        .then((response) => {
          EventRepository.notificationSend({
            label: i18n.t("Dispositivo actualizado con exito"),
            type: "success",
          });
          afterSubmit();
          history.push({
            pathname: `/admin/devices/detail/${props.match.params.key}`,
            state: {
              parent: parentId,
            },
          });
        })
        .catch((error) => {
          afterSubmit();
          dealWithError(error, "generic.error", (message, errors) => {
            analizeErrors(message, errors);
          });
        });
    } else {
      const formData = new FormData();
      if (deviceAux.item_file) {
        formData.append('item_file', deviceAux.item_file?.file_name ?? deviceAux.item_file);
      }
      for (let key in deviceAux) {
        if (key !== 'item_file') {
          formData.append(key, deviceAux[key]);
        }
      }
      DeviceService.createDevice(formData)
        .then((rta) => {
          afterSubmit();
          if (parentId) {
            history.push({
              pathname: `/admin/devices/detail/${parentId}`,
            });
          } else {
            history.push(`/admin/devices`);
          }
        })
        .catch((error) => {
          afterSubmit();
          dealWithError(error, "generic.error", (message, errors) => {
            analizeErrors(message, errors);
          });
        }).finally(() => {
          afterSubmit();
        });
    }
  };

  const handleGoBack = () => {
    if (parentId) {
      history.push({
        pathname: `/admin/devices/detail/${parentId}`,
      });
    } else {
      history.push(`/admin/devices/detail/${device.id}`);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let deviceAux = { ...device };
    deviceAux[name] = value;
    setdevice(deviceAux);
  };

  const mapZones = (locations) => {
    let zones = locations.filter((location) => {
      return location.type === "ZONE";
    });

    zones = zones.map((zone) => {
      zone.label = zone.name;
      zone.value = zone.name;
      return zone;
    });

    setzones(zones);
    return zones;
  };

  const mapSectors = (locations) => {
    let sectors = locations.filter((location) => {
      return location.type === "AREA";
    });

    sectors = sectors.map((zone) => {
      zone.label = zone.name;
      zone.value = zone.name;
      return zone;
    });

    setsectors(sectors);
    return sectors;
  };

  const handleSelect = (selectedOption, type) => {
    let deviceAux = { ...device };
    switch (type) {
      case "type":
        deviceAux.type = selectedOption;
        setdevice(deviceAux);
        break;
      case "node":
        deviceAux.node = selectedOption;
        deviceAux.zone = "";
        deviceAux.area = "";
        LocationService.locations({
          code: selectedOption.code,
          no_parent: "true",
          parent: null,
        }).then((locations) => {
          mapZones(locations.results);
        });
        setdevice(deviceAux);
        break;
      case "zone":
        deviceAux.zone = selectedOption;
        deviceAux.location = selectedOption;
        deviceAux.area = "";
        LocationService.locations({
          code: device.node.code,
          no_parent: "false",
          parent: selectedOption.id,
        }).then((locations) => {
          mapSectors(locations.results);
        });
        setdevice(deviceAux);
        break;
      case "sector":
        deviceAux.area = selectedOption;
        deviceAux.location = selectedOption;
        setdevice(deviceAux);
        break;

      default:
        break;
    }
  };

  const validateContinue = () => {
    if (device) {
      return (
        device.serial_number !== "" &&
        device.node !== ""
      );
    } else {
      return false;
    }
  };

  const headerOptions = [];
  const content = (
    <div className={"edit-device-content-wrapper"}>
      <div className={"edit-device-inner"}>
        <form onSubmit={handleCreate}>
          <DeviceForm
            device={device}
            handleChange={handleChange}
            handleSelect={handleSelect}
            error={errors}
            nodes={businessnodes}
            zones={zones}
            sectors={sectors}
            type={type}
            disabledFields={disabledFields[type]}
            handleChangeImage={handleChangeImage}
            handleDeleteImage={handleDeleteImage}
            showSelectorFiles={showSelectorFiles}
            msgInfoFiles={i18n.t("Video o imagen del dispositivo")}
          />
          <div className={"edit-device-button-wrapper"}>
            <button
              type="submit"
              className={`edit-device-button ${!validateContinue() &&
                "disabled"}`}
              onClick={handleCreate}
            >
              {type === "add_antenna" ? "CREAR" : "GUARDAR"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <div className="edit-device-container">
      <AdminLayout
        headerTitle={i18n.t(`${titleMapping[type]}`)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default EditDeviceView;
