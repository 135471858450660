import React, {useState, useEffect, useRef} from "react";
import {useHistory} from 'react-router-dom'

import i18n from "i18n";
import { AuthService } from "services/AuthService";
import { OrderService } from "services/OrderService";

import AdminLayout from "../../../components/MainApp/layouts/DesktopLayout";
import MainNotification from "../../../components/MainApp/atoms/Notification";
import BlueSummaryComponent from "../../../components/MainApp/organisms/BlueSummary";
import Loader from "../../../components/MainApp/atoms/Loader";

import "./styles.scss";
import SummaryButton from "../../../components/MainApp/atoms/SummaryButton";

const headerOptions = [

]
const blueSummaryItems = [
    {
        label: "Órdenes creadas hoy",
        number: 0
    },
    {
        label: "Total pendientes",
        number: 0
    },
    {
        label: "Total en proceso",
        number: 0
    }
]

const summaryButtonItems = [
    {
        id: 'REQ',
        icon: "iconos_reposicion",
        title: "Reposición",
        pending: "0",
        processing: "0",
        url: "/admin/orders/request"

    },
    {
        id: 'PUR',
        icon: "iconos_compras",
        title: "Compra",
        pending: "0",
        processing: "0",
        url: "/admin/orders/reubicacion"
    },{
        id: 'REC',
        icon: "iconos_ingresos",
        title: "Entradas",
        pending: "0",
        processing: "0",
        url: "/admin/orders/reception"

    },{
        id: 'PRE',
        icon: "iconos_egresos",
        title: "Salidas",
        pending: "0",
        processing: "0",
        url: "/admin/orders/preparation"

    },{
        id: 'REL',
        icon: "iconos_reubicacion",
        title: "Reubicación",
        processing: "0",
        url: "/admin/orders/relocation"
    },
    
]

export const OrdersView = () => {
  const history = useHistory();

  const [ summary, setSummary ] = useState({blueSummaryItems, summaryButtonItems})
  const [loading, setloading] = useState(false);
  const [node, _] = useState(AuthService.currentNodeValue());

  useEffect(() => {
    if (!node) {
        history.push(`/admin`);
    }

    setloading(true);
    OrderService.summaryOrder().then((response) => {
        setloading(false)
        mapRespone(response);
    });
  }, []);


  const types = ['REL', 'REQ', 'PRE']
  const mapRespone = (response) => {
    let summaryNew = {...summary};
    summaryNew.blueSummaryItems[0].number = response.today;
    summaryNew.blueSummaryItems[1].number = reducePerStatus('PEN', response.status)
    summaryNew.blueSummaryItems[2].number = reducePerStatus('PRO', response.status)


    summaryNew.summaryButtonItems = summaryNew.summaryButtonItems.map(item => {
        let newItem = {...item}

        if(response.status[item.id]){
            newItem.pending = response.status[item.id]['PEN']
            newItem.processing = response.status[item.id]['PRO']

        }
        return newItem
    })
    
    setSummary(summaryNew)
  }

  const reducePerStatus = (status, list) => {
      const listByStatus = Object.keys(list).map(key => list[key][status])
      return listByStatus.reduce(function(a,units) { return a + units }, 0)
  }


  const loadedContent = <div className="orders-contet-wrapper">
    <div className="orders-blue-summary-wrapper">
        <BlueSummaryComponent items={summary.blueSummaryItems}/>
    </div>

    <div className="orders-buttons-wrapper">
        {summary.summaryButtonItems.map(
            item => {
                return <div key={item.title} className="order-summary-button-wapper"><SummaryButton key={item.title} clickHandler={() => history.push(item.url)} icon={item.icon} title={item.title} pending={item.pending} processing={item.processing} /> </div>
            }
        )}
    </div>

    </div>

  const content = loading ? <Loader /> : loadedContent
  
    return(
        <div className="orders-container">
        <AdminLayout
          headerTitle={i18n.t("Ordenes") + (node ? ` (${AuthService.currentNodeValue().name})` : '')}
          headerOptions={headerOptions}
          content={content}
          navHidden={false}
        ></AdminLayout>
        <MainNotification
          label={"Tu producto se actualizó de manera exitosa."}
          open={false}
        ></MainNotification>
      </div>
    )
}

export default OrdersView;