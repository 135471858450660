const prepOrderKlassOptions = [
  {
    label: "Venta",
    id: "SALE",
  },
  {
    label: "Ajuste de stock",
    id: "ADJ",
  },
];

const receptionOrderKlassOptions = [
  {
    label: "Entrega del proveedor",
    id: "PUR",
  },
  {
    label: "Almacenar producto terminado",
    id: "PRO",
  },
  {
    label: "Devolucion del cliente",
    id: "REF",
  },
  {
    label: "Ajuste de stock",
    id: "ADJ",
  },
  {
    label: "Venta",
    id: "SALE",
  },
];

class Order {
  id = null;
  number = null;
  external_reference = null;
  type = null;
  klass = null;
  status = null;
  from_date = null;
  to_date = null;
  notes = null;
  node = null;
  order = null;
  creator = null;
  owner = null;
  customer = null;
  created_timestamp = null;
  created_at = null;
  updated_at = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }
}

class RequestOrder extends Order {
  destination = null;

  constructor(obj) {
    super();
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }
}

class PrepOrder extends Order {
  comment = null;

  constructor(obj) {
    super();
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

  getSelectedOption(options, id) {
    return options.find((option) => option.id === id);
  }

  loadStep1Fields(errors, accounts = [], clients = []) {
    let basicFields = [
      {
        type: "dropdown",
        data: {
          label: "Motivo",
          placeholder: "Motivo",
          required: true,
          name: "reason",
          selectedOption: this.klass
            ? this.getSelectedOption(prepOrderKlassOptions, this.klass)
            : "",
          value: this.klass,
          options: prepOrderKlassOptions,
          error: errors.klass && errors.klass,
          disabled: this.id ? true : false,
        },
      },
      {
        type: "dropdown",
        data: {
          label: "Responsable",
          placeholder: "Responsable",
          required: true,
          name: "owner",
          selectedOption: this.owner
            ? this.getSelectedOption(accounts, this.owner.id)
            : "",
          value: this.owner ? this.owner.id : null,
          options: accounts,
          error: errors.owner && errors.owner,
        },
      },
      {
        type: "textarea",
        data: {
          label: "Comentario (opcional)",
          required: false,
          id: "notes",
          name: "notes",
          value: this.notes ? this.notes : "",
          error: errors.notes && errors.notes,
        },
      },
    ];

    const newField = {};
    switch (this.klass) {
      case "SALE":
        const newField = {
          type: "dropdown",
          data: {
            label: "Cliente",
            placeholder: "Cliente",
            required: true,
            name: "client",
            selectedOption: this.client
              ? this.getSelectedOption(clients, this.client.id)
              : "",
            value: this.client ? this.client.id : null,
            options: clients,
            error: errors.client && errors.client,
          },
        };
        basicFields.splice(1, 0, newField);
        break;

      default:
        break;
    }

    return basicFields;
  }

  getDescriptionPanelView() {
    return [
      {
        title: "Motivo",
        value: this.klass,
      },
      {
        title: "Cliente",
        value: this.customer,
      },
      {
        title: "Responsable",
        value: this.owner ? this.owner.first_name : "",
      },
      {
        title: "Descripcion",
        value: this.description,
      },
      {
        title: "Fecha de creación",
        value: this.created_at,
      },
    ];
  }

  dataToSend() {
    return {
      type: this.type,
      klass: this.klass,
      notes: this.notes,
      owner: this.owner ? this.owner.id : null,
      client: this.client ? this.client.id : null,
    };
  }

  dataCreateMovement() {
    const data = {
      type: this.klass === "REQ" ? "MOVE" : "OUT",
      mov_class: this.klass === "REQ" ? "EXT_OUT_SIM" : "BAS",
      node_dest: this.klass === "REQ" ? this.order.node : null,
      requires_revision: true, // Por ahora todos validan, this.klass === "REQ",
    };

    return data;
  }
}

class ReceptionOrder extends Order {
  comment = null;

  constructor(obj) {
    super();
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

  getSelectedOption(options, id) {
    return options.find((option) => option.id === id);
  }

  loadStep1Fields(errors, accounts = [], clients = [], providers = []) {
    let basicFields = [
      {
        type: "dropdown",
        data: {
          label: "Motivo",
          placeholder: "Motivo",
          required: true,
          name: "reason",
          selectedOption: this.klass
            ? this.getSelectedOption(receptionOrderKlassOptions, this.klass)
            : "",
          value: this.klass,
          options: receptionOrderKlassOptions,
          error: errors.klass && errors.klass,
          disabled: this.id ? true : false,
        },
      },
      {
        type: "dropdown",
        data: {
          label: "Responsable",
          placeholder: "Responsable",
          required: true,
          name: "owner",
          selectedOption: this.owner
            ? this.getSelectedOption(accounts, this.owner.id)
            : "",
          value: this.owner ? this.owner.id : null,
          options: accounts,
          error: errors.owner && errors.owner,
        },
      },
      {
        type: "textarea",
        data: {
          label: "Comentario (opcional)",
          required: false,
          id: "notes",
          name: "notes",
          value: this.notes ? this.notes : "",
          error: errors.notes && errors.notes,
        },
      },
    ];

    let newField = {};
    switch (this.klass) {
      case "SALE":
        newField = {
          type: "dropdown",
          data: {
            label: "Cliente",
            placeholder: "Cliente",
            required: true,
            name: "client",
            selectedOption: this.client
              ? this.getSelectedOption(clients, this.client.id)
              : "",
            value: this.client ? this.client.id : null,
            options: clients,
            error: errors.client && errors.client,
          },
        };
        basicFields.splice(1, 0, newField);
        break;

      case "PUR":
        newField = {
          type: "dropdown",
          data: {
            label: "Proveedor",
            placeholder: "Proveedor",
            required: true,
            name: "provider",
            selectedOption: this.provider
              ? this.getSelectedOption(providers, this.provider.id)
              : "",
            value: this.provider ? this.provider.id : null,
            options: providers,
            error: errors.provider && errors.provider,
          },
        };
        basicFields.splice(1, 0, newField);
        break;

      default:
        break;
    }

    return basicFields;
  }

  getDescriptionPanelView() {
    return [
      {
        title: "Número de orden",
        value: this.code,
      },
      {
        title: "Fecha de creación",
        value: new Date(this.created_at).toLocaleString(),
      },
      {
        title: "Motivo",
        value: this.klass,
      },
      {
        title: "Cliente",
        value: this.client ? this.client.first_name : "",
      },
      {
        title: "Responsable",
        value: this.owner ? this.owner.first_name : "",
      },
      {
        title: "Descripcion",
        value: this.description,
      },
    ];
  }

  dataToSend() {
    return {
      type: this.type,
      klass: this.klass,
      notes: this.notes,
      owner: this.owner ? this.owner.id : null,
      client: this.client ? this.client.id : null,
      provider: this.provider ? this.provider.id : null,
      process_output: ["SALE"].includes(this.klass),
    };
  }
}

class RelocationOrder extends Order {
  destination = null;
  origin = null;

  constructor(obj) {
    super();
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

  dataToSend() {
    return {
      node: this.node?.code,
      destination: this.destination ? this.destination.code : null,
      origin: this.origin? this.origin.code : null,
      type: this.type,
    };
  }
}

class RoadMapMovement {
  id = null;
  order = null;
  movement = null;
  owner = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }
}

export { Order, RequestOrder, PrepOrder, ReceptionOrder, RelocationOrder, RoadMapMovement };
