import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tippy";
import { isMobile } from "helpers/Mobile";
import Tag from "components/MainApp/organisms/OrdersV3/Tag";

import "./styles.scss";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
};
const ItemStock = ({ stock, list, tagsInMov = [] }) => {
  const [row, setRow] = useState({});
  const [opened, setOpened] = useState(false);
  const columns = ["title", "sku", "tags"];

  useEffect(() => {
    const newRow = {
      ...stock,
      tags: `${stock.readItems} / ${stock.expectedUnits}`,
    };
    setRow(newRow);
  }, [stock]);

  const handleClick = () => {
    const openedAux = opened;
    setOpened(!openedAux);
  };

  const renderColumn = (key) => {
    return (
      <div
        className={`reading-table-item-${key} reading-table-item`}
        data-stock-id={key}
        onClick={handleClick}
        key={key}
      >
        <Tooltip
          html={<div style={tooltipStyle}>
            {key === "tags"
              ? `${stock.readItems} / ${stock.expectedUnits}`
              : stock[key]}
          </div>}
          followCursor={true}
          position="left"
          offset={25}
        >
          <p>
            {key === "title" && <img src={stock.image} />}
            {key === "tags"
              ? `${stock.readItems} / ${stock.expectedUnits}`
              : stock[key]}
          </p>
        </Tooltip>
      </div>
    );
  };

  return (
    <div
      className="reading-table-item-wrapper"
      data-automation-id="stock-item-wrapper"
    >
      {!isMobile && (
        <div
          className="reading-table-item-container"
          data-automation-id="stock-item"
        >
          {columns.map((column_key) => {
            return renderColumn(column_key);
          })}

          <div
            className={`reading-table-item-button ${opened ? "opened" : ""}`}
            data-automation-id="select-stock"
            data-stock-id={stock.sku}
            onClick={handleClick}
            key={"button"}
          >
            <i className="icon-chevron"></i>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="reading-table-item-mobile-container">
          <div className={"reading-table-item-row"}>
            <div className={"reading-table-item-column image"}>
              <div>
                <img src={stock.image} />
              </div>
            </div>
            <div className={"reading-table-item-column title"}>
              <h1>{stock.title}</h1>
              <p>SKU {stock.sku}</p>
            </div>
            <div className={"reading-table-item-column button"}>
              <div
                className={`reading-table-item-button ${
                  opened ? "opened" : ""
                }`}
                data-automation-id="select-stock"
                data-stock-id={stock.sku}
                onClick={handleClick}
                key={"button"}
              >
                <i className="icon-chevron"></i>
              </div>
            </div>
          </div>
          <div className={"reading-table-item-row tags"}>
              <p>Tags <span>{stock.readItems}</span>/{stock.expectedUnits}</p>
          </div>
        </div>
      )}

      <div className={`reading-table-list ${opened ? "opened" : ""}`}>
        <div className={"reading-table-tags-separator"}></div>
        {list.map((tag) => {
          return (
            <Tag
              tag={tag}
              epc={tag.epc}
              type="verification"
              selected={tag.selected}
              message={tag.message}
              key={tag.tag}
              tagsInMov={tagsInMov}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ItemStock;
