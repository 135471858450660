import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import usePagination from "hooks/usePagination";
import useRequest from "hooks/useRequest";

import i18n from "i18n";
import { PublicService } from "services/PublicService.jsx";
import { DeviceService } from "services/DeviceService.js";
import { TagService } from "services/TagService";
import { EventRepository } from "helpers/EventRepository";

import { steps } from "./data/steps";
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import StepProgress from "components/MainApp/atoms/Wizard/StepProgress";
import StepOne from "./Wizard/Step1";
import StepTwo from "./Wizard/Step2";
import StepThree from "./Wizard/Step3";

import AddConfigTagSuccess from "../AddConfigTagSuccess";

import { isMobile } from "helpers/Mobile";
import { ParseDate, formatDateToYyyyMmDd } from "helpers/Parsers";

import "./styles.scss";
import { be } from "date-fns/locale";

const initialTagInfo = {
  batch: "",
  due_date: formatDateToYyyyMmDd(new Date()),
  sscc: "",
  origin: "",
  destination: ""
};

const initialError = {
  success: true,
  message: "",
  errors: []
};

const AddTagView = props => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();
  const [activeStep, setActiveStep] = useState("0");
  const [sentSuccess, setSentSuccess] = useState(false);
  const [tag, settag] = useState(initialTagInfo);
  const [configTagId, setConfigTagId] = useState();
  const [configTag, setConfigTag] = useState();

  const [productResultList, setproductResultList] = useState([]);
  const [addedList, setaddedList] = useState([]);
  const [selectedToAddProductList, setselectedToAddProductList] = useState([]);
  const [
    list,
    currentPage,
    pageSize,
    pageList,
    pageQuantity,
    changePageSize,
    changePage,
    changeList
  ] = usePagination([], 0, 3);

  const [printer, setPrinter] = useState();
  const [printers, setPrinters] = useState([]);

  const [generatedTags, setGeneratedTags] = useState([]);
  const [itemsWithGeneratedTags, setItemsWithGeneratedTags] = useState([]);
  const [modalPersistOpen, setmodalPersistOpen] = useState(false);

  const headerOptions = [];

  useEffect(() => {
    DeviceService.devices({ type: "PRINTER" }).then(data => {
      data = data.map(device => {
        return { label: device.name, option: device.id };
      });
      setPrinters(data);
    });
  }, []);

  useEffect(() => {
    if (!configTag) {
      return;
    }

    beforeSubmit();
    if (configTag.type === "PRODUCT") {
      PublicService.products({ page_size: 999999, ordering: "-created_at" }).then(
        response => {
          response.results = response.results.map(product => {
            product.selected = false;
            product.units = 1;
            product.stock = 1;
            return product;
          });
          setproductResultList(response.results);
          afterSubmit();
        }
      ).catch(error => {
        afterSubmit();
      });
    } else {
      PublicService.containers({
        page_size: 999999,
        ordering: "-created_at",
        type: configTag.type
      }).then(response => {
        response.results = response.results.map(product => {
          product.selected = false;
          product.units = 1;
          product.stock = 1;
          return product;
        });
        setproductResultList(response.results);
        afterSubmit();
      }).catch(error => {
        afterSubmit();
      });
    }
  }, [configTag]);

  useEffect(() => {
    const id = props.match.params.key;
    setConfigTagId(id);

    TagService.configTag(id).then(configTag => {
      setConfigTag(configTag);
    });

    let persistedObj = JSON.parse(localStorage.getItem(id));
    if (persistedObj) {
      settag(persistedObj.tag);
      setaddedList(persistedObj.addedList);
      changeList(persistedObj.addedList, currentPage, pageSize);
      setPrinter(persistedObj.printer);
      setPrinters(persistedObj.printers);
      setGeneratedTags(persistedObj.generateTags);
      setItemsWithGeneratedTags(persistedObj.itemsWithGeneratedTags);
      setproductResultList(persistedObj.productResultList);
      setActiveStep(persistedObj.activeStep);
    }
  }, []);

  const handleGenerateTags = e => {
    e.preventDefault();
    if (
      generatedTags &&
      itemsWithGeneratedTags &&
      generatedTags.length > 0 &&
      itemsWithGeneratedTags.length > 0
    ) {
      setActiveStep("1");
      return;
    }

    beforeSubmit(true);
    let items = addedList.map(item => {
      let data = {
        sku: item.sku,
        quantity: item.units,
        variables: {
          name: item.name,
          batch: tag.batch,
          expiration: tag.due_date,
          date: ParseDate(new Date())
        }
      };
      if (configTag.type === "PRODUCT") {
        data["variables"]["description"] = item.description;
        data["variables"]["date"] = ParseDate(new Date());
      } else if (configTag.type === "BOX") {
        data["variables"]["sscc"] = tag.sscc;
        data["variables"]["origin"] = tag.origin;
        data["variables"]["destination"] = tag.destination;
      } else {
        data["variables"]["sscc"] = tag.sscc;
        data["variables"]["origin"] = tag.origin;
        data["variables"]["destination"] = tag.destination;
      }
      return data;
    });

    TagService.generateTags(configTagId, items)
      .then(tags => {
        afterSubmit();
        setGeneratedTags(tags);
        setItemsWithGeneratedTags(organizeTagsByItem(addedList, tags));
        setActiveStep("1");
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error"
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t(
                "Alguno de los productos seleccionados tiene problema."
              ),
              type: "error"
            });
          }
        });
      });
  };

  const organizeTagsByItem = (items, tags) => {
    return items.map(item => {
      item.tags = tags.filter(t => t.sku === item.sku);
      return item;
    });
  };

  const handleRemoveTags = () => {
    const data = generatedTags.map(t => {
      return { rfid: t.rfid };
    });

    beforeSubmit();

    TagService.removeTags(data)
      .then(_ => {
        afterSubmit();
        setGeneratedTags([]);
        setItemsWithGeneratedTags([]);
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", message => {
          EventRepository.notificationSend({
            label: message,
            type: "error"
          });
        });
      });
  };

  const handleFinish = e => {
    e.preventDefault();

    const data = {
      device: printer.option,
      rfids: generatedTags.map(t => t.rfid)
    };

    beforeSubmit();

    TagService.printTags(data)
      .then(tags => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("Se imprimieron los tags de manera exitosa."),
          type: "success"
        });
        localStorage.removeItem(configTagId);
        history.push(`/admin/config_tags`);
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", message => {
          EventRepository.notificationSend({
            label: message,
            type: "error"
          });
        });
      });
  };

  const handleClose = () => {
    handleRemoveTags();
    localStorage.removeItem(configTagId);
    setmodalPersistOpen(false);
    goToList();
  };

  const handleFinishStepTwo = () => {
    localStorage.removeItem(configTagId);
    setmodalPersistOpen(false);
    goToList();
  }

  const onlyCloseModal = () => {
    setmodalPersistOpen(false);
  }

  const handlePersist = () => {
    let data = {};
    data.tag = tag;
    data.addedList = addedList;
    data.printer = printer;
    data.printers = printers;
    data.generateTags = generatedTags;
    data.itemsWithGeneratedTags = itemsWithGeneratedTags;
    data.productResultList = productResultList;
    data.selectedToAddProductList = selectedToAddProductList;
    data.activeStep = activeStep;
    localStorage.setItem(configTagId, JSON.stringify(data));
    setmodalPersistOpen(false);
    goToList();
  };

  const persistenceGuard = () => {
    setmodalPersistOpen(true);
  };

  const persistTagPrintData = data => {
    localStorage.setItem(data.id, data);
  };

  const goToList = () => {
    history.push(`/admin/config_tags`);
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let tagAux = { ...tag };
    tagAux[name] = value;

    settag(tagAux);
  };

  const handleSelect = (selectedOption, type) => {
    setPrinter(selectedOption);
  };

  const handleSelectToAddProduct = product => {
    let selectedToAddProductListNew = [...selectedToAddProductList];
    let ind = selectedToAddProductListNew.findIndex(p => {
      return p.id === product;
    });
    if (ind !== -1) {
      selectedToAddProductListNew = selectedToAddProductListNew.filter(p => {
        return p.id !== product;
      });
    } else {
      let newProduct = productResultList.find(p => {
        return p.id === product;
      });
      newProduct.selected = true;
      selectedToAddProductListNew.push(newProduct);
    }

    setselectedToAddProductList(selectedToAddProductListNew);
  };

  const handleSelectedProductQuantityChange = (productId, number) => {
    let productResultListNew = [...productResultList];
    let productNew = productResultListNew.find(p => p.id === productId);

    productNew.units = number;
    setproductResultList(productResultListNew);
  };

  const handleAdd = () => {
    let addedListNew = [...addedList];
    selectedToAddProductList.forEach(product => {
      let addedInd = addedList.findIndex(p => {
        return p.id === product.id;
      });
      if (addedInd === -1) {
        addedListNew.push({ ...product });
      } else {
        let productSelected = {
          ...productResultList.find(p => p.id === product.id)
        };
        let productAddedIndex = addedListNew.findIndex(
          p => p.id === product.id
        );
        addedListNew[productAddedIndex].units =
          Number(addedListNew[productAddedIndex].units) +
          Number(productSelected.units);
      }

      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });

    let productResultListNew = productResultList.map(p => {
      p.units = 1;
      return p;
    });

    setproductResultList(productResultListNew);
    setselectedToAddProductList([]);
  };

  const handleAddProduct = (productId, number) => {
    number = number === 0 ? 1 : number;

    let addedListNew = [...addedList];
    let productAddedIndex = addedListNew.findIndex(p => p.id === productId);
    addedListNew[productAddedIndex].units = Number(number);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const handleDeleteAddedProduct = productId => {
    let addedListNew = [...addedList];

    addedListNew = addedListNew.filter(p => p.id !== productId);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const validateStep0 = () => {
    if (tag) {
      return (
        tag.batch &&
        tag.batch !== "" &&
        tag.due_date &&
        tag.due_date !== "" &&
        addedList.length > 0
      );
    } else {
      return false;
    }
  };

  const validateStep1 = () => {
    if (tag) {
      return tag.width !== "" && tag.height !== "";
    } else {
      return false;
    }
  };

  const validateStep2 = () => {
    if (tag) {
      return printer;
    } else {
      return false;
    }
  };

  const goToStep = step => {
    setActiveStep(step);
  };

  const resetTags = () => {
    handleRemoveTags();
    setActiveStep("0");
  };

  const headerTitle = sentSuccess ? "Plantilla creada" : "Generación de Tags";

 
  const step0 = (
    <StepOne
      handleChange={handleChange}
      resultList={productResultList}
      searchValue={""}
      products={isMobile ? addedList : pageList}
      totalProducts={addedList.length}
      handleSelectToAddProduct={handleSelectToAddProduct}
      handleSelectedProductQuantityChange={handleSelectedProductQuantityChange}
      handleAdd={handleAdd}
      handleAddProduct={handleAddProduct}
      selectedProducts={selectedToAddProductList.map(p => p.id)}
      handleDeleteAddedProduct={handleDeleteAddedProduct}
      changePage={changePage}
      pagination={{
        pageQuantity: pageQuantity,
        currentPage: currentPage
      }}
      handleContinue={handleGenerateTags}
      enableContinue={validateStep0()}
      type={configTag?.type}
      tag={tag}
      error={errors}
      handleClose={handleClose}
      handlePersist={handlePersist}
      onlyCloseModal = {onlyCloseModal}
      modalPersistOpen={modalPersistOpen}
    />
  );

  const step1 = (
    <StepTwo
      handleContinue={() => setActiveStep("2")}
      handleEdit={() => resetTags()}
      enableContinue={validateStep1()}
      tag={tag}
      error={errors}
      addedProducts={itemsWithGeneratedTags}
      handleClose={handleClose}
      handlePersist={handlePersist}
      modalPersistOpen={modalPersistOpen}
      onlyCloseModal = {onlyCloseModal}
      handleFinishStepTwo={handleFinishStepTwo}
    ></StepTwo>
  );

  const step2 = (
    <StepThree
      handleFinish={handleFinish}
      goBackFunc={() => goToStep("1")}
      printer={printer}
      handleSelect={handleSelect}
      error={errors}
      printers={printers}
      enableContinue={validateStep2()}
      handleClose={handleClose}
      handlePersist={handlePersist}
      modalPersistOpen={modalPersistOpen}
      onlyCloseModal = {onlyCloseModal}
    ></StepThree>
  );

  // const stepList = [step0, step1, step2];
  const stepList = {
    "0": step0,
    "1": step1,
    "2": step2
  };

  const addSuccess = (
    <div className={`add-tag-success`}>
      <AddConfigTagSuccess
        id={tag && tag.id}
        name={tag && tag.name}
        boldButtonFunc={goToList}
      />
    </div>
  );

  const content = (
    <div className={`add-tag-content`}>
      {!sentSuccess && (
        <div className={`add-tag-wrapper`}>
          <StepProgress steps={steps} activeStep={activeStep} />
          <div className={`add-tag-step-wrapper`}>{stepList[activeStep]}</div>
        </div>
      )}
    </div>
  );

  return (
    <div className="add-tag-container">
      <AdminLayout
        headerTitle={i18n.t(headerTitle)}
        headerOptions={headerOptions}
        content={sentSuccess ? addSuccess : content}
        navHidden={true}
        goBackFunc={persistenceGuard}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default AddTagView;
