function isHex(h) {
  var regexp = /^[0-9a-fA-F]+$/;
  return regexp.test(h);
}

/**
 * If the number is a hex prepend 0x to the number
 * @param {*} number
 * @returns BigInt
 */
function toBigInt(number) {
  if(!Number.isInteger(number) && !isHex(number)){
    console.error('toBigInt Invalid number:', number);
    throw new Error('Invalid number');
  }

  if(isHex(number)) number = "0x" + number;
  return BigInt(number);
}

export { toBigInt };