import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { TagService } from "../../../../services/TagService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";

import ConfigTagForm from "./ConfigTagForm";

import "./styles.scss";


const EditDeviceView = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [configTag, setConfigTag] = useState();

  useEffect(() => {
    if (!props.match.params.key) return;
    beforeSubmit();
    const id = props.match.params.key;
    TagService.configTag(id)
      .then((response) => {
        afterSubmit();
        setConfigTag(response)
      })
      .catch((error) => {
        afterSubmit();
      });
  }, []);


  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    beforeSubmit();
    const _configTag = { ...configTag };
    _configTag.type = configTag["type"].id;
    _configTag.mem_user = configTag["mem_user"].value;
    _configTag.mem_epc = configTag["mem_epc"].value;
    TagService.updateConfigTag(props.match.params.key, _configTag)
      .then((response) => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("Tag actualizado con exito"),
          type: "success",
        });
        history.push(`/admin/config_tags/detail/${props.match.params.key}`);
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const handleGoBack = () => {
    history.push(`/admin/config_tags/detail/${props.match.params.key}`);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let _configTag = { ...configTag };
    _configTag[name] = value;

    setConfigTag(_configTag);
  };

  const changeConfigTag = (_configTag) => {
    setConfigTag(_configTag);
  }


  const headerOptions = [];
  const content = (
    <div className={"edit-config-tag-content-wrapper"}>
      <div className={"edit-config-tag-inner"}>
        <form onSubmit={handleSubmit}>
          <ConfigTagForm
            configTag={configTag}
            handleChange={handleChange}
            error={errors}
            //disabledFields={disabledFields}
            changeConfigTag={changeConfigTag}
          />
          <div className={"edit-config-tag-button-wrapper"}>
            <button
              type="submit"
              className={`edit-config-tag-button`}
              onClick={handleSubmit}
            >
              {"GUARDAR"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <div className="edit-config-tag-container">
      <AdminLayout
        headerTitle={i18n.t(`Editar Tag`)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default EditDeviceView;
