import React, { useState, useEffect } from "react";
import { LocationService } from "services/LocationService";
import { BusinessNodeService } from "services/BusinessNodeService";

import SelectFilter from "components/MainApp/atoms/Filters/SelectFilter";
import "./styles.scss";

import filterData from "../data/filters";
import { isMobile } from "helpers/Mobile";

let appliedEnabled = false;

const AuditoriaFilterPanel = ({
  handleApplyProp,
  opened = false,
  stockq=20,
  handleOpen,
  handleDeleteProp,
  handleSelectionChange,
}) => {
  const [filters, setFilters] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);

  //Get Sucursales at the start
  useEffect(() => {
    BusinessNodeService.businessNodes({no_page: 1}).then(nodes => {
      nodes = nodes.map(n => {
        n.id = n.code;
        n.label = n.name;
        n.value = n.name;
        return n
      })

      filterData[0].options = nodes;
      filterData[1].options = [];
      filterData[2].options = [];
      setFilters(filterData);
    })
  }, []);


  const handleApply = (selectedOption) => {
    handleApplyProp(selectedOption);
  }

  //Save selection
  const handleSelection = (option, label) => {
    // const selectedOptionsAux = [...selectedOptions];
    // const f = filters.findIndex((f) => f.label === type);
    // selectedOptionsAux[f] = e;
    // setSelectedOptions(selectedOptionsAux);
    // getOptions(e, type)

    const index = filters.findIndex((f) => f.label === label);
    if(index === 0 ){
      getZones(option.code)
      const _selectedOptions = [option, {}, {}]
      setSelectedOptions(_selectedOptions)
      appliedEnabled = true;
    }

    if(index === 1 ) {
      appliedEnabled = true;
      getAreas(option.id)
      const _selectedOptions = [selectedOptions[0], option, {}]
      setSelectedOptions(_selectedOptions)
    }

    if(index === 2){
      appliedEnabled = true;
      const _selectedOptions = [...selectedOptions]
      _selectedOptions[2] = option
      setSelectedOptions(_selectedOptions)
    }
    handleSelectionChange(option);
  };

  const getZones = (node) => {
    LocationService.locationsAll({no_parent: "true", node: node}).then(locations => {
      locations = locations.map(n => {
        n.label = n.name;
        n.value = n.name;
        return n
      })
      const newFilters = [...filters];

      newFilters[1].options = locations;
      newFilters[2].options = []
      setFilters(newFilters);
    })
  };
  const getAreas = (zone) => {
    LocationService.locationsAll({ parent: zone}).then(locations => {
      locations = locations.map(n => {
        n.label = n.name;
        n.value = n.name;
        return n
      })
      const newFilters = [...filters];

      newFilters[2].options = locations;
      setFilters(newFilters);
    })
  };


  const handleDelete = () => {
    setSelectedOptions([]);
    filterData[1].options = [];
    filterData[2].options = [];
    setFilters(filterData);
    appliedEnabled = false;
    handleDeleteProp();
  };

  return (
    <div className={`auditoria-filter-panel-container-out`}>
      {(!opened && isMobile) &&(
        <div className={`auditoria-filter-panel-summary`}>
          <div className={`auditoria-filter-panel-summary-row`}>
            <div className={`auditoria-filter-panel-summary-filters`}>
              {filters.map((filter, i) => {
                return (
                  selectedOptions[i]?.label && (
                    <>
                      <div key={i}>
                        {filter.label} {selectedOptions[i].label}
                      </div>
                      <div className={"separator"}></div>
                    </>
                  )
                );
              })}
            </div>
            <div className={`auditoria-filter-panel-summary-button`} onClick={handleOpen}>
              <i className={`icon-iconos_filtros`} />
            </div>
          </div>
          <div className={`auditoria-filter-panel-summary-row`}>
            <p className={'auditoria-filter-panel-summary-stock'}>Stock({stockq})</p>
          </div>
        </div>
      )}
      {(opened || !isMobile) && (
        <div className={`auditoria-filter-panel-container`}>
          <div className={`auditoria-filter-panel-inputs`}>
            {filters?.map((filter, i) => {
              if (filter.options && filter.options?.length > 0){
                return (
                  <SelectFilter
                    key={filter.label}
                    label={filter.label}
                    options={filter.options}
                    selectedOption={
                      selectedOptions && selectedOptions[i]
                    }
                    handleSelection={(e) => handleSelection(e, filter.label)}
                    placeholder={filter.placeholder}
                  ></SelectFilter>
                );
              }

            })}
          </div>
          <div className={`auditoria-filter-panel-buttons`}>
            <button
              className={`auditoria-filter-panel-filter-apply ${
                appliedEnabled ? "" : "disabled"
              }`}
              disabled={!appliedEnabled}
              onClick={() => handleApply(selectedOptions)}
            >
              APLICAR
            </button>
            <button
              className="auditoria-filter-panel-filter-delete"
              onClick={handleDelete}
            >
              BORRAR
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuditoriaFilterPanel;
