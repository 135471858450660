import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";

import SelectAutocomplete from 'shared/components/autocomplete';

import "./styles.scss";

const countryOptions = [
    {
      label: "Almacen 1"
    },
    {
      label: "Bodega 1"
    },
    {
      label: "Deposito Central"
    },
    {
      label: "Deposito 2"
    }
  ]
export class AddBusinessStepOne extends React.Component {
  render() {
    const { businessnode, handleChange, handleSelect, handleContinue, countries,
            regions, provinces, cities, enableContinue, error } = this.props;

    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-businessnode-wizard-step-one-content">
        <div className="add-businessnode-wizard-step-one-field" data-automation-id="input-name">
          <MainInput
            label={"Nombre"}
            required={true}
            id={"businessnode-name"}
            name="name"
            value={businessnode ? businessnode.name : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.name && error.name}
          />
        </div>
        <div className="add-businessnode-wizard-step-one-field" data-automation-id="input-country">
          <SelectAutocomplete
            id="businessnode-country"
            name="country"
            label={"País"}
            options={countries}
            placeholder={"País"}
            selected={businessnode ? businessnode.country : {}}
            required={true}
            error={error.country && error.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "country")
            }
          />
        </div>
        <div className="add-businessnode-wizard-step-one-field" data-automation-id="input-region">
          <SelectAutocomplete
            id="businessnode-region"
            name="region"
            label={"Comunidad"}
            options={regions}
            placeholder={"Comunidad"}
            selected={businessnode ? businessnode.region : {}}
            required={true}
            disabled={!businessnode || !businessnode.country || businessnode.country === ""}
            error={error.region && error.region}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "region")
            }
          />
        </div>
        <div className="add-businessnode-wizard-step-one-field" data-automation-id="input-state">
          <SelectAutocomplete
            id="businessnode-state"
            name="state"
            label={"Provincia"}
            options={provinces}
            placeholder={"Provincia"}
            selected={businessnode ? businessnode.state : {}}
            disabled={!businessnode || !businessnode.region || businessnode.region === ""}
            required={true}
            error={error.country && error.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "state")
            }
          />
        </div>
        <div className="add-businessnode-wizard-step-one-field" data-automation-id="input-locality">
          <SelectAutocomplete
            id="businessnode-locality"
            name="locality"
            label={"Municipio"}
            options={cities}
            placeholder={"Municipio"}
            selected={businessnode ? businessnode.locality : {}}
            disabled={!businessnode || !businessnode.state || businessnode.state === ""}
            required={true}
            error={error.country && error.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "city")
            }
          />
        </div>
        <div className="add-businessnode-wizard-step-one-field" data-automation-id="input-address">
        <MainInput
            label={"Dirección"}
            required={true}
            id={"businessnode-address"}
            name="address"
            value={businessnode ? businessnode.address : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.addess && error.addess}
          />
        </div>
        <div className={"add-businessnode-wizard-step-one-action-wrapper"}>
          <button type="submit" className={`add-businessnode-wizard-step-one-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-businessnode-wizard-step-one-container">
        <Step title="" content={stepContent}></Step>
      </div>
    );
  }
}

export default AddBusinessStepOne;
