import "./styles.scss";

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";
import FileSaver from "file-saver";

import { MovementService } from "services/MovementServkce";

import { Container, List } from "@material-ui/core";

import { ParseDate } from "helpers/Parsers";
import { getUrl } from "helpers/orders/OrderHelper";
import useRequest from "hooks/useRequest";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import StockTable from "components/MainApp/organisms/Stock";
import LoadButtonsContainer from "components/MainApp/organisms/Product/LoadButtonsContainer";
import FichaFilterPanel from "./FichaDeStockFilterPanel";

import headers from "./headers";
import Loader from "components/MainApp/atoms/Loader";
import excel from "assets/images/excel.svg";
import excelwhite from "assets/images/excelwhite.svg";

import MainNotification from "components/MainApp/atoms/Notification";
import TableComponent from "shared/components/table/TableComponent";
import { ParseMovement } from "helpers/ParseMovement";
import StockTableItemMobile from "components/MainApp/organisms/Stock/TableItemMobile";
import ItemStock from "shared/components/item-stock/ItemStock";
import { NotFoundItems } from "views/Admin/components/NotFoundItems";
import { isMobile } from "helpers/Mobile";
import { fi } from "date-fns/locale";

const initialFilters = {
  item: undefined,
  node: undefined,
  location: undefined,
  date_from: undefined,
  date_to: undefined
};

const FichaDeStock = () => {
  const { loading, beforeSubmit, afterSubmit } = useRequest();
  const [rows, setRows] = useState([]);
  const history = useHistory();

  const [movements, setMovements] = useState([]);

  const [filters, setFilters] = useState(initialFilters);
  const [noStock, setNoStock] = useState(false);
  const [mobilePanelOpen, setMobilePanelOpen] = useState(true);
  const myRef = useRef(0);
  const [isSearched, setSearched] = useState(true);
  const [balance, setBalance] = useState(0);

  let scrolling = false;

  useEffect(() => {
    fetchBalance();
  }, [filters.item?.key, filters.node?.id, filters.location?.id]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  useEffect(() => {
    filter(false);
  }, [filters]);

  useEffect(() => {
    if (movements) {
      const list = [];
      movements.forEach(movement => {
        list.push({
          date: movement.date,
          type: movement.type,
          order: movement.order,
          reason: movement.reason,
          in: movement.in,
          out: movement.out,
          total: movement.total,
          schema: movement
        });
      });
      setRows(list);
    }
  }, [movements]);

  const handleApplyFilters = _filters => {
    setSearched(false);
    setMobilePanelOpen(false);
    setFilters(_filters);
    setSearched(true);
  };

  const deleteSearchFilter = () => {
    setRows([])
  }

  const handleScroll = event => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (loading || scrolling) return;
    scrolling = true;
  };

  const filter = function(firstLoad = false, ordering = 'm.updated_at desc') {
    if (!filters.item || !filters.node) {
      return;
    }

    beforeSubmit();
    const data = {
      node: filters.node.id,
      location: filters.location?.id,
      date_from: filters.date_from,
      date_to: filters.date_to,
      ordering,
    };
    MovementService.movementItemsHistory(filters.item.key, data)
      .then(response => {
        afterSubmit();
        scrolling = false;

        if (firstLoad && response.count === 0) {
          setNoStock(true);
          return;
        }

        let itemsNew = response.map(i => {
          return {
            id: `${i.date}${i.order_code}`,
            date: ParseDate(i.date),
            type: i18n.t(`orders.types.${i.order_type}`),
            order: i.order_code,
            reason: i18n.t(`orders.klasses.${i.order_klass}`),
            in: i.count_entry == 0 ? "" : i.count_entry,
            out: i.count_egress == 0 ? "" : i.count_egress,
            total: i.balance,
            order_id: i.order_id,
            order_type: i.order_type
          };
        });

        setMovements(itemsNew);
      })
      .catch(e => {
        afterSubmit();
        scrolling = false;
      });
  };

  const downloadReport = () => {
    if (!filters.item || !filters.node || !filters.node > 0) {
      return;
    }

    const data = {
      node: filters.node.id,
      location: filters.location?.id,
      date_from: filters.date_from,
      date_to: filters.date_to,
      format_end: "excel"
    };

    beforeSubmit();
    MovementService.movementItemsHistoryDownload(filters.item.key, data)
      .then(blob => {
        afterSubmit();
        FileSaver.saveAs(blob, "reporte.xls");
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const onRowClick = row => {
    const movement = row.schema;
    let url = getUrl(movement.order_id, movement.order_type, filters.node.id);
    if (url) {
      history.push(url);
    }
  };

  const handleRequestSort = (ordering) => {
    if (ordering.includes('order')) {
      ordering = 'o.' + ordering.replace('order', 'code');
    }else if (ordering.includes('type')) {
      ordering = 'o.' + ordering;
    }else if (ordering.includes('reason')) {
      ordering = 'o.' + ordering.replace('reason', 'klass');
    }else if (ordering.match(/in /)) {
      ordering = ordering.replace('in', 'count_entry');
    }else if (ordering.match(/out /)) {
      ordering = ordering.replace('out', 'count_egress');
    }else if (ordering.includes('date')) {
      ordering = 'm.' + ordering.replace('date', 'updated_at');
    }
    filter(false, ordering);
  }

  const fetchBalance = () => {
    if (!filters.node) return;

    const data = {
      node: filters.node?.id,
      location: filters.location?.id,
    };

    MovementService.movementItemsHistory(filters.item.key, data)
      .then(response => {
        if (response.length > 0) {
          const lastRecord = response[response.length - 1];
          setBalance(lastRecord?.balance);
        }
      })
      .catch(error => {
        console.error("Error fetching balance:", error);
      });
  };

  const headerOptions = [];

  const headerButtons = (
    <div
      className="excel-button"
      onClick={downloadReport}
      style={{
        width: "30px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
      }}
    >
      <img style={{ height: "30px" }} src={isMobile ? excelwhite : excel} />{" "}
    </div>
  );

  const contentDesktop = (
    <React.Fragment>
      <FichaFilterPanel
        handleApply={handleApplyFilters}
        deleteSearchFilter={deleteSearchFilter}
        opened={mobilePanelOpen}
      />
      {rows && rows.length > 0 && (
        <div className="table-products-table">
          <div className="available-balance">
            <span>Saldo Actual Disponible </span>
            <span className="table-products-top-total-bold">
              ({balance})
            </span>
          </div>
          <div className="table-products-top-table">
            <div className="table-products-top-total">
              Movimientos{" "}
              <span className="table-products-top-total-bold">
                ({rows.length})
              </span>
            </div>
          </div>

          <div className="table-products-content">
            {rows && rows.length > 0 && (
              <TableComponent
                sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
                headCells={headers}
                dense={true}
                showPaginator={true}
                rows={rows}
                rows_per_page={10}
                RightIcon={<i className="icon-chevron-derecha"></i>}
                onRowClick={onRowClick}
                changeRequestSort={handleRequestSort}
                backendOrdering={true}
              />
            )}
          </div>
        </div>
      )}

      {rows && rows.length === 0 && isSearched && (
        <NotFoundItems />
      )}
    </React.Fragment>
  );

  const contentMobile = (
    <React.Fragment>
      <div className="table-products-filter-container"></div>
      <FichaFilterPanel
        handleApply={handleApplyFilters}
        deleteSearchFilter={deleteSearchFilter}
        opened={mobilePanelOpen}
      />
      <div className="table-products-table">
        <div className="table-products-top-table">
          <div className="table-products-top-total">
            <span>Movimientos </span>
            <span className="table-products-top-total-bold">
              ({rows.length})
            </span>
            <span>{" - "}Saldo Actual Disponible </span>
            <span className="table-products-top-total-bold">
              ({rows.length > 0 ? rows[rows.length - 1]["total"] : 0})
            </span>
          </div>
          <div
            className={"table-products-active-filter"}
            onClick={() => {
              setMobilePanelOpen(true);
            }}
          >
            <i className={mobilePanelOpen == false ? "icon-iconos_filtros table-products-top-icon" : ""} ></i>
          </div>
        </div>

        <div className="table-stock-mobile" data-automation-id="product-table">
          <div className="table-stock-content">
            {rows.map(stock_item => {
              return (
                <div className="table-stock-content-item" key={stock_item.id}>
                  <ItemStock stockItem={stock_item} handleClick={onRowClick} />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {rows && rows.length === 0 && isSearched && (
        <NotFoundItems />
      )}
    </React.Fragment>
  );

  const tableContent = isMobile ? contentMobile : contentDesktop;

  const noProductContent = (
    <div className="table-load-products-content">
      <div className="table-load-products-title">
        Aquí visualizarás el listado de tus productos y su stock disponible.
      </div>
      <LoadButtonsContainer
        handleSingleProductClick={() =>
          history.push("/admin/products/load-single-product")
        }
        handleMassiveProductClick={() =>
          history.push("/admin/products/load-massive-product")
        }
      ></LoadButtonsContainer>
    </div>
  );

  const productsContent = noStock ? noProductContent : tableContent;

  const content = (
    <div className="table-products-ficha-stock-wrapper" ref={myRef}>
      {productsContent}
    </div>
  );

  return (
    <div className="load-products-container">
      <AdminLayout
        headerTitle={i18n.t("Ficha de Stock")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        customButtons={headerButtons}
        goBackFunc={() => history.push("/admin/stock")}
      ></AdminLayout>
      {loading && <Loader />}
      <MainNotification
        label={"Tu producto se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default FichaDeStock;
