import { ParseDateTime } from "helpers/Parsers.js"
import i18n from "i18n";
class BusinessNode
{
    id = null;
    code = null;
    number = null;
    type = null;
    name = null;
    address = null;
    locality = null;
    state = null;
    country = null;
    owner = null;
    email = null;
    phone = null;
    measure_height = null;
    measure_width = null;
    measure_depth = null;
    capacity = null;
    created_at = null;

    constructor(obj) {    
	    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
        for (var prop in obj) this[prop] = obj[prop];
        this.id = obj.code
      }
      
    getDescriptionPanelView() {
        return [
            {
                title: "Número de sucursal",
                value: this.name
            },
            {
                title: "ID",
                value: this.code
            },
            {
                title: "Fecha de creación",
                value: ParseDateTime(this.created_at)
            },
            {
                title: "Localización",
                value: this.address
            }
        ]
    }

    getContactPanelView() {
        return [
            {
                title: 'Responsable',
                value: this.owner
            },
            {
                title: 'Telefono',
                value: this.phone
            },
            {
                title: 'Email',
                value: this.email
            }
        ]
    }

    getDimensionsPanelView(){
        return [
            {
                title: 'Profundidad (mts)',
                value: `${this.measure_depth/1000} mts` 
            },
            {
                title: "Alto (mts)",
                value: `${this.measure_height/1000} mts`
            },
            {
                title: "Ancho (mts)",
                value: `${this.measure_width/1000} mts`
            },
            {
                title: "Capacidad",
                value: `${this.capacity == null ? 'Sin informar' : this.capacity +' productos' }`
            }
        ]
    }

    getLocationPanelView(){
        return {
            id: this.code,
            name: this.name,
        }
    }

    getGenericTableView(){
        return {
            id: this.code,
            name: this.name,
            country: this.country
            ? this.country.label
              ? this.country.label
              : this.country
            : "",
            locality: this.locality
            ? this.locality.label
              ? this.locality.label
              : this.locality
            : "",
            email: this.email,
            phone: this.phone
        }
    }
}
export {
    BusinessNode
}
