import React from "react";

import Step from "components/MainApp/atoms/Wizard/Step";
import Icon from "components/MainApp/atoms/Icon/icon";
import InputText from "components/MainApp/atoms/Forms/MainInput";
import Item from "components/MainApp/organisms/Tags/Tag";
import DeviceSelector from "components/Functionals/DeviceSelector";

import "./styles.scss";

export const ThirdPartyTagAssociationStepOne = ({
  enabledRead,
  typeRead,
  refRead,
  associateRead,
  priorityRead,
  receiveEvent,
  readTags,
  selectedTags,
  searchValue,
  handleSearch,
  searchedValue,
  goBackFunc,
  handleDelete,
  handleDeleteAll,
  handleSend,
}) => {
  const stepContent = (
    <div className="associate-tags-step-two-content">
      <DeviceSelector
        enabled={enabledRead}
        typeRead={typeRead}
        refRead={refRead}
        associateRead={associateRead}
        priorityRead={priorityRead}
        receiveEvent={receiveEvent}
      />
      <div
        className="associate-tags-step-two-field"
        data-automation-id="input-search"
      >
        <div className="associate-tags-title">
          Ingresa el nro. o escanea TagID
        </div>
        <form className="load-movement-items-input" onSubmit={handleSearch}>
          <InputText
            label="Ingresa número"
            handleChange={searchValue}
            value={searchedValue}
          />
          <Icon name="barcode" width={30} height={25} fill={"#3C7AF5"} />
        </form>
        <div className="load-movement-items-count">{readTags.length}</div>
        <div className="load-movement-items-list">
          {readTags &&
            readTags.map((tag) => {
              return (
                <Item
                  tag={tag}
                  handleDelete={handleDelete}
                  handleSelect={null}
                  selected={false}
                  type={null}
                  message={''}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
  return (
    <div className="associate-tags-step-two-container">
      <Step content={stepContent}></Step>
    </div>
  );
};

export default ThirdPartyTagAssociationStepOne;
