import React, {useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import i18n from "i18n";
import {AuthService} from 'services/AuthService';
import AdminLayout from "../../../components/MainApp/layouts/DesktopLayout";
import ExtraMenuItem from "../../../components/MainApp/layouts/ExtraMenuItem";
import {step0} from "./data/step0"
import {step1} from "./data/step1"
import "./styles.scss";

import packageJson from "./../../../../package.json";

const headerOptions = []

export const ExtraMenuView = (props) => {
    const [permissions, setPermissions] = useState([]);
    const [step, setstep] = useState(step0);
    const steps = [step0, step1]

    useEffect(() => {
      setPermissions(AuthService.getPermissions());
      if(props.match.params.stepNumber){
        goTo(props.match.params.stepNumber)
      }
    }, []);

    const goTo = (stepNumber) => {
        setstep(steps[stepNumber])
    }

    const content = <div className={"extra-menu-content"}>
        <div className="extra-menu-list">
        {step.items.filter(i => {
          return permissions.includes(i.permission) || i.permission === 'all'
        }).map((item) => {
            if(item.to){
                return <NavLink key={item.title} to={item.to}><ExtraMenuItem key={item.title} title={item.title} arrow={item.arrow}/></NavLink>
            }else{
                return <div key={item.title} onClick={() => goTo(item.toStep)}><ExtraMenuItem key={item.title} title={item.title} arrow={item.arrow}/></div>
            }
        })}
        {step.headerTitle == "Más opciones" && (
          <>
            <div className={'extra-menu-logout'} onClick={() => AuthService.logout()}>
              <ExtraMenuItem title={i18n.t("Cerrar sesión")} arrow={false}/>
            </div>
            <span className="version-container">v {packageJson.version}</span>
          </>
        )}
        </div>
    </div>

    return <div className={"extra-menu-container"}>
        <AdminLayout
          headerTitle={i18n.t(step.headerTitle)}
          headerOptions={headerOptions}
          content={content}
          navHidden={step.navHidden}
          goBackFunc={step.goBackTo === undefined ? null : () => goTo(step.goBackTo)}
        ></AdminLayout>
    </div>
}

export default ExtraMenuView;